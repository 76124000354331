import React from 'react';
import PropTypes from 'prop-types';

import CardWrapper from 'molecules/CardWrapper';
import CompetitionBlock from 'organisms/CompetitionBlock';

import List from 'atoms/List';
import Group, { Fill } from 'atoms/Group';
import GeneralText from 'atoms/GeneralText';
import Badge from 'atoms/Badge';
import Icon from 'atoms/Icon';

import { FootballBall } from 'assets/icons';

import InlinePlayerCard from 'organisms/InlinePlayerCard';

import parseDateTime from 'services/parseDateTime';

import formatMetrics from 'services/formatMetrics';
import { camelCaseToSpace, sortBy } from 'services/utilities';

const CurrentTeamCard = ({ currentTeam, teamNumber, gameType }) => {
  const isHome = currentTeam && currentTeam.homeTeam;
  return (
    <div style={{ padding: 15, paddingTop: 0 }}>
      <CardWrapper
        topLabel={
          <Group isFullWidth isVerticalCentered>
            <GeneralText isMedium color="#FFFFFF">
              <strong>Selecting Team</strong>
            </GeneralText>
            <Fill />
            <Badge isLarge isDanger={teamNumber === 2}>
              <GeneralText isLarge color={'white'}>
                <strong>{teamNumber}</strong>
              </GeneralText>
            </Badge>
          </Group>
        }
        style={{ margin: 'auto', maxWidth: 415 }}>
        {!currentTeam && (
          <div
            style={{
              height: 356,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}>
            <GeneralText style={{ marginBottom: 20, textAlign: 'center' }}>
              <b>
                Select a team lineup by
                <br />
                clicking on a team logo above.
              </b>
            </GeneralText>
            <Icon size={100} color="#F2F2F2">
              <FootballBall />
            </Icon>
          </div>
        )}
        {currentTeam && (
          <>
            <CompetitionBlock
              selectedTeam={{
                name: currentTeam.teamName,
                logo: isHome
                  ? currentTeam.homeTeamLogoUrl
                  : currentTeam.awayTeamLogoUrl,
              }}
              competitorTeam={{
                name: !isHome
                  ? currentTeam.homeTeamName
                  : currentTeam.awayTeamName,
                logo: !isHome
                  ? currentTeam.homeTeamLogoUrl
                  : currentTeam.awayTeamLogoUrl,
              }}
              homeTeamName={currentTeam.homeTeamName}
              startTime={parseDateTime(currentTeam.competitionStart, 'hh:mm A')}
              date={parseDateTime(currentTeam.competitionStart, 'MMM DD, YYYY')}
            />
            <div style={{ padding: '0 15px 15px' }}>
              <List>
                {sortBy(currentTeam.competitors, 'isBackupCompetitor').map(
                  (item, index) => (
                    <InlinePlayerCard
                      projections={{
                        overunder: formatMetrics(
                          item.competitor.projections.overUnder,
                          item.competitor.position
                        ),
                        statpicks: formatMetrics(
                          item.competitor.projections.statPicks,
                          item.competitor.position
                        ),
                      }}
                      gameType={gameType}
                      fullName={
                        item.competitor.firstName +
                        ' ' +
                        item.competitor.lastName
                      }
                      picture={item.competitor.headshotUrl}
                      stats={{
                        label: camelCaseToSpace(item.metric, true),
                        name: item.metric,
                        value: item.competitor.metricsAvgs[item.metric],
                      }}
                      isBackup={item.isBackupCompetitor}
                      key={index}
                    />
                  )
                )}
              </List>
            </div>
          </>
        )}
      </CardWrapper>
    </div>
  );
};

CurrentTeamCard.defaultProps = {};

CurrentTeamCard.propTypes = {
  currentTeam: PropTypes.object,
  teamNumber: PropTypes.number,
  gameType: PropTypes.string,
};

export default CurrentTeamCard;
