import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import IMask from 'imask';

import { Group, GeneralText, Button, Space } from 'atoms';

import PlayerAvatar from 'molecules/PlayerAvatar';
import PersonHeading from 'molecules/PersonHeading';

import AnimatedBackground from './AnimatedBackground';

const mask = IMask.createMask({
  mask: 'num',
  blocks: {
    num: {
      mask: Number,
      scale: 0,
      min: 0,
      max: 999,
    },
  },
});

const Input = styled.input`
  appearance: none;
  text-align: center;
  background: none;
  border: 0;
  width: 200px;
  outline: 0;
  padding: 0;
  font-size: 34px;
  font-weight: bold;
  letter-spacing: 15px;
  margin-right: -15px;
  margin-left: -15px;
  padding-left: 13px;
  border-bottom: 2px dashed;
  padding-bottom: 10px;
`;

const InputValueCard = ({
  cardDescription,
  fullName,
  position,
  status,
  picture,
  inputLabel,
  onConfirm,
}) => {
  const [value, setValue] = useState(null);

  const inputRef = useRef();

  useEffect(() => {
    if (inputRef && inputRef.current) inputRef.current.focus();
  }, [inputRef]);

  return (
    <div>
      <div
        style={{
          position: 'relative',
          zIndex: 1,
          textAlign: 'center',
          overflow: 'hidden',
        }}>
        <GeneralText>
          <b>{cardDescription}</b>
        </GeneralText>
        <Space isVertical size={30} />

        <PlayerAvatar source={picture} flagLabel={status} />

        <Space isVertical size={15} />

        <PersonHeading subtitle={position}>{fullName}</PersonHeading>

        <Space isVertical size={30} />

        <Input
          type="number"
          pattern="[0-9]*"
          value={value}
          ref={inputRef}
          onChange={({ target }) => {
            target.value = mask.resolve(target.value);
            setValue(target.value);
          }}
        />
        <Space isVertical size={10} />

        <GeneralText>{inputLabel}</GeneralText>

        <Space isVertical size={30} />

        <Group isCentered>
          <Button isSmall isSecondary onClick={() => onConfirm(value)}>
            Confirm
          </Button>
        </Group>
      </div>

      <AnimatedBackground />
    </div>
  );
};

InputValueCard.defaultProps = {
  onConfirm: () => {},
};

InputValueCard.propTypes = {
  cardDescription: PropTypes.string,
  inputLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  status: PropTypes.string,
  fullName: PropTypes.string,
  picture: PropTypes.string,
  position: PropTypes.string,
};

export default InputValueCard;
