import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import Group from 'atoms/Group';
import GeneralText from 'atoms/GeneralText';
import Icon from 'atoms/Icon';
import { Plus, Minus } from 'assets/icons';

import colors from 'assets/themes/colors';
import AnimatedCheckmark from './AnimatedCheckmark';
import { isRealNumber } from 'services/utilities';

const Button = styled.button`
  cursor: pointer;
  position: relative;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  border: 1px solid;
  border-color: ${colors.primary};
  background-color: transparent;
  transition: all 0.2s ease-out;
  outline: none;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  padding-top: 3px;
  box-shadow: 0px 0px 2px ${colors.tertiary};
`;

// eslint-disable-next-line react/prop-types
const AnimatedTouch = ({ children, onClick, ...rest }) => {
  return (
    <Button onClick={() => onClick()} {...rest}>
      {children}
    </Button>
  );
};

const StatpicksMechanics = ({
  stats,
  onChange,
  onClickValue,
  updatedValue,
}) => {
  const [value, updateValue] = useState(null);

  useEffect(() => {
    if (isRealNumber(updatedValue * 1) && updatedValue !== value) {
      updateValues(updatedValue);
    }
  }, [updatedValue]);

  const handleChanges = action => {
    let newValue = value;
    if (action === 'add') newValue = value + 1;
    if (action === 'subtract' && value > 0) newValue = value - 1;
    updateValues(newValue);
  };

  function updateValues(valueToUse) {
    updateValue(valueToUse * 1);
    onChange('statpicks', valueToUse * 1);
  }

  const checkmark = position => {
    const style = {
      left: {
        position: 'absolute',
        top: -35,
        left: -35,
      },
      right: {
        position: 'absolute',
        top: -35,
        right: -30,
      },
    };
    return <AnimatedCheckmark style={style[position]} />;
  };

  return (
    <Group isCentered isFullWidth isVerticalCentered spaceSize={20}>
      <AnimatedTouch onClick={() => handleChanges('subtract')}>
        <Icon size={24}>
          <Minus />
        </Icon>
      </AnimatedTouch>

      {/* TODO: Update this with Button.isBare */}
      <Group
        isCentered
        isVertical
        onClick={onClickValue}
        style={{ width: 100, height: 55, position: 'relative' }}>
        <GeneralText isLarge style={{ fontSize: 32, height: 37 }}>
          {typeof (value * 1) == 'number' && value != null
            ? checkmark('right')
            : null}
          <b>
            {typeof (value * 1) == 'number' && value != null
              ? value
              : '_ _ _ _'}
          </b>
        </GeneralText>
        <GeneralText isSmall>{stats.label}</GeneralText>
      </Group>

      <AnimatedTouch onClick={() => handleChanges('add')}>
        <Icon color={colors.primary} size={24}>
          <Plus />
        </Icon>
      </AnimatedTouch>
    </Group>
  );
};

StatpicksMechanics.defaultProps = {
  stats: {
    label: '----',
    value: 0,
  },
  onChange: () => {},
  onClickValue: () => {},
};

StatpicksMechanics.propTypes = {
  stats: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }),
  onChange: PropTypes.func,
  onClickValue: PropTypes.func,
  updatedValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.number,
};

export default StatpicksMechanics;
