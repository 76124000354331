import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import * as actions from './actions';

import { SessionContext } from 'containers/SessionManager';

import Routes from './Routes';

import checkConnection from 'hooks/checkConnection';
import useEmailStatus from 'hooks/useEmailStatus';

import AppContainer from 'organisms/AppContainer';

import useAnalytics from 'hooks/useAnalytics';
import useRollbar from 'hooks/useRollbar';

export const MainContext = React.createContext({});

export const MainContainer = ({ fetchUserData, resetGame, signOut }) => {
  const { session, isReady, isAnonymous } = useContext(SessionContext);

  const isOnline = checkConnection();

  const isReadyToFetchUserData = isOnline && isReady && !isAnonymous;

  useAnalytics(); // Set tracker instance for the first time

  const rollbar = useRollbar();

  useEffect(() => {
    // TODO: Make the RESET more user friendly post MVP
    let version = process.env.npm_package_version;
    let appVersion = localStorage.getItem('app_version');
    if (!appVersion) localStorage.setItem('app_version', version);
    if (appVersion) {
      version = version.split('.');
      appVersion = appVersion.split('.');
      const changes = {
        major: version[0] != appVersion[0],
        minor: version[1] != appVersion[1],
        patch: version[2] != appVersion[2],
      };
      if (changes.major || changes.minor) {
        localStorage.setItem('app_version', version.join('.'));
        signOut();
      }
    }
  }, []);

  useEffect(() => {
    if (isReadyToFetchUserData) {
      fetchUserData(session.uid);
    }
  }, [isReady, isReadyToFetchUserData]);

  const EmailStatusFlag = useEmailStatus(
    !isAnonymous && session && !session.emailVerified
  );

  if (isReady === null) return null; // TODO: Replace with splashscreen

  if (rollbar && isReadyToFetchUserData) {
    rollbar.configure({
      payload: {
        person: {
          id: session.uid,
          email: session.email,
        },
      },
    });
  }

  return (
    <MainContext.Provider
      value={{
        isOnline,
        resetGame,
        signOut,
      }}>
      <AppContainer>
        <Routes />
      </AppContainer>

      <EmailStatusFlag />
    </MainContext.Provider>
  );
};

MainContainer.propTypes = {
  fetchUserData: PropTypes.func,
  isLoading: PropTypes.bool,
  resetGame: PropTypes.func,
  rollbar: PropTypes.object, // Rollbar instance to configure userId and email
  signOut: PropTypes.func,
};

export default connect(
  ({ main }) => ({
    isLoading: main.isLoading,
  }),
  { ...actions }
)(MainContainer);
