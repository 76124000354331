import React from 'react';
import PropTypes from 'prop-types';

import { Button, GeneralText, Group, Icon, Space } from 'atoms';

import { Trophy, InfoCircle } from 'assets/icons';
import { navigate } from '@reach/router';

import callEmailVerification from 'hooks/callEmailVerification';
import useUserData from 'hooks/useUserData';

import { returnTo } from 'services/utilities';

const Intro = ({ onClickToProceed }) => {
  const [EmailVerification, verify, resend] = callEmailVerification();
  const [session] = useUserData();

  const isVerified = session.emailVerified;

  return (
    <>
      {!isVerified ? (
        <Group isVertical isCentered spaceSize={20} padding={30}>
          <Space isVertical size={40} />

          <Icon size={80}>
            <InfoCircle />
          </Icon>

          <GeneralText isMedium>
            Please check your inbox and click on the verification link that we
            just sent you.
          </GeneralText>

          <GeneralText isMedium>
            If you did not receive an email from us please click Resend below
            and make sure the email you entered is correct.
          </GeneralText>

          <Group spaceSize={10}>
            <Button isSmall isSecondary onClick={resend}>
              RESEND
            </Button>
            <Button isSmall onClick={verify}>
              VERIFY
            </Button>
          </Group>

          <Space isVertical size={10} />
          <GeneralText>
            <b>Why do you need to verify your email?</b>
          </GeneralText>
          <GeneralText isSmall>
            Meteor Fantasy just needs to double check that you are a real person
            with a valid email. Don’t worry, it doesn’t take that much time to
            do — just check your email and click on the link.
          </GeneralText>

          <Space isVertical size={40} />
        </Group>
      ) : (
        <Group isVertical isCentered spaceSize={20} padding={30}>
          <Space isVertical size={40} />

          <Icon size={80}>
            <Trophy />
          </Icon>

          <GeneralText isMedium>
            Verify your account and start winning real money today!
          </GeneralText>

          <Button isSmall isSecondary onClick={onClickToProceed}>
            OK, TAKE ME THERE!
          </Button>

          <Button
            isSmall
            isSecondary
            isOutlined
            onClick={() => navigate(`/${returnTo() || 'gametype'}`)}>
            SKIP
          </Button>
          <Space isVertical size={40} />
        </Group>
      )}
      <EmailVerification />
    </>
  );
};

Intro.propTypes = {
  onClickToProceed: PropTypes.func,
};

export default Intro;
