import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import theme from 'assets/themes/default';
import colors, { convertHexToRGB } from 'assets/themes/colors';

const Wrapper = styled.span`
  display: block;
  position: relative;
  width: 100%;
`;

const LoadingContainer = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
`;

const LeftIcon = styled.span`
  position: absolute;
  left: 10px;
  display: flex;
  align-items: center;
  ${({ isTextarea }) =>
    isTextarea
      ? css`
          top: 8px;
        `
      : css`
          top: 50%;
          transform: translateY(-50%);
        `}
`;

const RightIcon = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  display: flex;
  align-items: center;
`;

const InputComponent = styled.input`
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${colors.secondary};
  color: ${({ value, type }) => {
    if (type === 'date' && !value) return colors.mattedGray;
    return colors.primary;
  }};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: ${({ isSmall }) => (isSmall ? 12 : 14)}px;
  font-family: inherit;
  justify-content: flex-start;
  min-height: ${({ isSmall }) => (isSmall ? 32 : 38)}px;
  padding-left: ${({ hasLeftIcon }) => (hasLeftIcon ? 35 : 10)}px;
  padding-right: ${({ hasRightIcon }) => (hasRightIcon ? 35 : 10)}px;
  &:focus {
    outline: none;
    border: 1px solid ${convertHexToRGB(colors.primary, 0.8)};
    box-shadow: 0 0 3px 2px ${convertHexToRGB(colors.secondary, 0.6)};
  }
  &:hover {
    border: 1px solid ${convertHexToRGB(colors.primary, 0.8)};
  }
  &:disabled {
    background-color: ${colors.light};
    border-color: ${colors.tertiary};
  }
  &::-moz-placeholder {
    color: ${colors.mattedGray};
  }
  &::-webkit-input-placeholder {
    color: ${colors.mattedGray};
  }
  &:-moz-placeholder {
    color: ${colors.mattedGray};
  }
  &:-ms-input-placeholder {
    color: ${colors.mattedGray};
  }
`;

InputComponent.displayName = 'Input';

const TextareaComponent = styled.textarea`
  width: 100%;
  min-height: ${({ isSmall }) => (isSmall ? 50 : 70)}px;
  border-radius: 6px;
  border: 1px solid ${colors.secondary};
  color: ${colors.primary};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: ${({ isSmall }) => (isSmall ? 12 : 14)}px;
  justify-content: flex-start;
  align-items: flex-start;
  resize: none;
  padding-left: ${({ hasLeftIcon }) => (hasLeftIcon ? 35 : 10)}px;
  padding-right: ${({ hasRightIcon }) => (hasRightIcon ? 35 : 10)}px;
  font-family: ${theme.fonts.body};
  padding-top: 8px;
  &:focus {
    outline: none;
    border: 1px solid ${convertHexToRGB(colors.primary, 0.8)};
    box-shadow: 0 0 3px 2px ${convertHexToRGB(colors.secondary, 0.6)};
  }
  &:hover {
    border: 1px solid ${convertHexToRGB(colors.primary, 0.8)};
  }
  &:disabled {
    background-color: ${colors.light};
    border-color: ${colors.disabled};
  }
  &::-moz-placeholder {
    color: ${colors.mattedGray};
  }
  &::-webkit-input-placeholder {
    color: ${colors.mattedGray};
  }
  &:-moz-placeholder {
    color: ${colors.mattedGray};
  }
  &:-ms-input-placeholder {
    color: ${colors.mattedGray};
  }
`;

TextareaComponent.displayName = 'Textarea';

const Input = ({
  disableAutoComplete,
  isLoading,
  isTextarea,
  leftIcon,
  rightIcon,
  style,
  ...rest
}) => {
  const defaultProps = {
    ...rest,
    autoComplete: disableAutoComplete ? 'new-password' : null,
  };
  return (
    <Wrapper style={style}>
      {isLoading && <LoadingContainer></LoadingContainer>}
      {leftIcon && <LeftIcon isTextarea={isTextarea}>{leftIcon}</LeftIcon>}
      {rightIcon && <RightIcon isTextarea={isTextarea}>{rightIcon}</RightIcon>}
      {isTextarea ? (
        <TextareaComponent
          {...defaultProps}
          hasLeftIcon={!!leftIcon}
          hasRightIcon={!!rightIcon}></TextareaComponent>
      ) : (
        <InputComponent
          {...defaultProps}
          hasLeftIcon={!!leftIcon}
          hasRightIcon={!!rightIcon}
          style={style}
        />
      )}
    </Wrapper>
  );
};

Input.defaultProps = {
  disableAutoComplete: false,
  isLoading: false,
  isTextarea: false,
  value: '',
};

Input.propTypes = {
  disableAutoComplete: PropTypes.bool,
  isLoading: PropTypes.bool,
  isTextarea: PropTypes.bool,
  leftIcon: PropTypes.any,
  rightIcon: PropTypes.any,
  style: PropTypes.object,
  value: PropTypes.string,
};

export default Input;
