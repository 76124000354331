import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import * as actions from './actions';

import { navigate } from '@reach/router';

import { MainContext } from 'containers/MainContainer';

import useGameSteps from 'hooks/useGameSteps';
import useUserData from 'hooks/useUserData';

import Wrapper from './components/Wrapper';

import colors from 'assets/themes/colors';
import { Button, Group, GeneralText, Icon, Title } from 'atoms';
import ContainerWithBackground from 'molecules/ContainerWithBackground';

import ThumbsUp from 'assets/icons/thumbs-up.svg';
import InfoCircle from 'assets/icons/info-circle.svg';
import Trophy from 'assets/icons/trophy.svg';

import YouEntered1 from 'assets/media/images/you-entered-1.png';

import NotLoggedIn from './components/NotLoggedIn';
import Unverified from './components/Unverified';
import NoFunds from './components/NoFunds';
import UnverifiedEmail from './components/UnverifiedEmail';
import IncompleteContest from './components/IncompleteContest';
import Error from './components/Error';

import useAnalytics from 'hooks/useAnalytics';

export const SubmitGame = ({
  submitContest,
  isSubmitting,
  submitStatus,
  resetSubmitStatus,
  error,
}) => {
  const { isOnline, resetGame } = useContext(MainContext);

  const [session] = useUserData();

  const { isAnonymous } = session;

  const [isFirstAttempt, toggleAttempts] = useState(true);

  const { logEvent } = useAnalytics();

  let isReadyToSubmit = null;
  let contest = null;

  useGameSteps([], (isReady, data) => {
    isReadyToSubmit =
      isReady && isOnline && !isSubmitting && isFirstAttempt && !isAnonymous;
    contest = data;
  });

  const isPaid = contest.modality === 'paid';
  const hasFunds = contest.hasFunds;
  const isSubmitted = submitStatus === 'sent';

  if (!session.emailVerified) isReadyToSubmit = false;
  if (isPaid && !session.verified) isReadyToSubmit = false;
  if (!hasFunds && isPaid) isReadyToSubmit = false;

  useEffect(() => {
    if (isReadyToSubmit) {
      toggleAttempts(false);
      session.state.getIdToken().then(token => {
        submitContest(resetGame, token);
      });
    }
    return () => {
      if (submitStatus === 'sent') {
        resetGame();
        resetSubmitStatus();
      }
    };
  }, [isReadyToSubmit]);

  useEffect(() => {
    if (submitStatus === 'sent')
      logEvent('submit', { item_name: 'Successful' });
    if (submitStatus === 'error')
      logEvent('submit', { item_name: 'Not_successful' });
  }, [isSubmitted]);

  if (isAnonymous) return <NotLoggedIn />;
  if (!session.emailVerified) return <UnverifiedEmail />;
  if (isPaid && !session.verified) return <Unverified />;
  if (!hasFunds && isPaid) return <NoFunds />;

  if (error && submitStatus === 'error' && !isSubmitting) {
    return <Error message={error} />;
  }

  if (!isReadyToSubmit && submitStatus !== 'sent' && !isSubmitting) {
    return <IncompleteContest />;
  }

  return (
    <Wrapper>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <ContainerWithBackground
          source={YouEntered1}
          style={{
            flex: 1,
            height: 'auto',
          }}>
          <Group
            isVertical
            isCentered
            isVerticalCentered
            padding={20}
            spaceSize={60}
            style={{
              flex: 1,
              height: '100%',
              width: '100%',
              maxWidth: 450,
              margin: 'auto',
            }}>
            <Title color={colors.white} isLarge>
              {isSubmitting && 'Almost there'}
              {isSubmitted && 'You`ve Entered!'}
              {error && 'Oops :('}
            </Title>
            <div style={{ height: 100 }}>
              {isSubmitted && (
                <Icon color={colors.white} size={110}>
                  <ThumbsUp />
                </Icon>
              )}
              {isSubmitting && (
                <Icon color={colors.white} size={110}>
                  <Trophy />
                </Icon>
              )}
              {error && (
                <Icon color={colors.white} size={110}>
                  <InfoCircle />
                </Icon>
              )}
            </div>
            <GeneralText color={colors.white} isLarge>
              <b>
                {isSubmitting && 'Submitting contest...'}
                {isSubmitted &&
                  'Check back later to see how you did! Good Luck!'}
              </b>
            </GeneralText>
          </Group>
        </ContainerWithBackground>

        <div style={{ width: '100%', padding: '15px 15px 20px' }}>
          {error ? (
            <Button
              isFullWidth
              isSecondary
              style={{ maxWidth: 450, margin: 'auto' }}
              onClick={() => navigate('/gameplay')}>
              GO BACK
            </Button>
          ) : (
            <Button
              isFullWidth
              disabled={!isSubmitted}
              style={{ maxWidth: 450, margin: 'auto' }}
              onClick={() => {
                resetGame();
                resetSubmitStatus();
                navigate('/gametype');
              }}>
              NEXT CONTEST
            </Button>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

SubmitGame.defaultProps = {};

SubmitGame.propTypes = {
  error: PropTypes.string,
  isSubmitting: PropTypes.bool,
  resetGame: PropTypes.func,
  resetSubmitStatus: PropTypes.func,
  submitContest: PropTypes.func,
  submitStatus: PropTypes.string,
};

export default connect(
  store => ({
    ...store.submit,
  }),
  { ...actions }
)(SubmitGame);
