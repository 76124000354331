import React from 'react';

import { navigate } from '@reach/router';

import Wrapper from './Wrapper';

import colors from 'assets/themes/colors';
import { Button, Group, GeneralText, Icon, Title } from 'atoms';
import ContainerWithBackground from 'molecules/ContainerWithBackground';

import { UserCircle } from 'assets/icons';

import YouEntered1 from 'assets/media/images/you-entered-1.png';

const Unverified = () => {
  return (
    <Wrapper>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <ContainerWithBackground
          source={YouEntered1}
          style={{
            flex: 1,
            height: 'auto',
          }}>
          <Group
            isVertical
            isCentered
            isVerticalCentered
            padding={20}
            spaceSize={60}
            style={{
              flex: 1,
              height: '100%',
              width: '100%',
              maxWidth: 450,
              margin: 'auto',
            }}>
            <Title color={colors.white} isLarge>
              Account not verified!
            </Title>
            <div style={{ height: 100 }}>
              <Icon color={colors.white} size={110}>
                <UserCircle />
              </Icon>
            </div>
            <GeneralText color={colors.white} isLarge>
              <b>Verify your account to proceed!</b>
            </GeneralText>
          </Group>
        </ContainerWithBackground>
        <div style={{ width: '100%', padding: '15px 15px 20px' }}>
          <Button
            isFullWidth
            style={{ maxWidth: 450, margin: 'auto' }}
            onClick={() => navigate('/account-verification')}>
            VERIFY ACCOUNT
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

export default Unverified;
