import React from 'react';
import PropTypes from 'prop-types';

import GameInstance from 'organisms/GameInstance';

import { compareArrayOfObjects } from 'services/utilities';

const EntryFees = ({ onSelect, selected, data, hideProgressBar }) => {
  if (!selected) return null;

  let payout = selected.payout;

  const entryFees = [...data]
    .filter(item => !item.isDisabled)
    .sort(compareArrayOfObjects('entryFee'));
  const maxEntries = selected.maxEntries;
  const currentUsersLength = Object.keys(selected.usersWithEntries).length;
  const payoutType = payout == 0 ? 50 : 25;
  const totalPrice = ((selected.entryFee * maxEntries) / 100) * 90;
  payout = totalPrice / ((maxEntries / 100) * payoutType);

  if (maxEntries < 1) payout = 0;

  return (
    <div style={{ marginTop: -10, padding: '20px 20px 10px' }}>
      <GameInstance
        payout={payout}
        payoutType={payoutType}
        activeParticipants={currentUsersLength}
        totalParticipants={maxEntries}
        selectedValue={selected.entryFee}
        entryFees={entryFees}
        hideProgressBar={hideProgressBar}
        onEntryFeeSelect={selected => {
          const filteredData = [...data].filter(
            item => item.entryFee == selected
          );
          if (filteredData && filteredData[0]) onSelect(filteredData[0]);
        }}
      />
    </div>
  );
};

EntryFees.defaultProps = {
  data: [],
};

EntryFees.propTypes = {
  data: PropTypes.array,
  hideProgressBar: PropTypes.bool,
  onSelect: PropTypes.func,
  selected: PropTypes.object,
};

export default EntryFees;
