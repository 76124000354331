import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Group from 'atoms/Group';
import ToggleContainer from 'atoms/ToggleContainer';
import GeneralText from 'atoms/GeneralText';
import Title from 'atoms/Title';
import Icon from 'atoms/Icon';
import { ThumbsUpSolid, ThumbsDownSolid } from 'assets/icons';

import colors from 'assets/themes/colors';
import AnimatedCheckmark from './AnimatedCheckmark';

const OverUnderMechanics = ({ name, stats, selected, onChange }) => {
  const [currentSelection, setSelection] = useState(selected);

  const handleChanges = ({ name, checked }) => {
    onChange(name, checked);
    //ToggleContainers must have unique names, so we split at hyphen to get
    //relevant "over" or "under" string for setting appropriate teamsSelection
    if (name) setSelection(name.split('-')[1]);
  };

  const checkmark = position => {
    const style = {
      left: {
        position: 'absolute',
        top: -35,
        left: -35,
      },
      right: {
        position: 'absolute',
        top: -35,
        right: -35,
      },
    };
    return <AnimatedCheckmark style={style[position]} />;
  };

  //For CardSwiper, unique ToggleContainer names are needed to avoid
  //changing all ToggleContainers onChange
  const underName = name + '-under';
  const overName = name + '-over';

  return (
    <Group isCentered isVerticalCentered isFullWidth spaceSize={15}>
      <ToggleContainer
        name={underName}
        checked={currentSelection === 'under'}
        onChange={handleChanges}
        render={({ isChecked }) => (
          <Group isVertical isCentered isVerticalCentered spaceSize={6}>
            <Icon
              color={isChecked ? colors.positive : colors.primary}
              size={24}>
              <ThumbsDownSolid />
            </Icon>
            <GeneralText
              isSmall
              color={isChecked ? colors.positive : colors.primary}>
              <b>Under</b>
              {isChecked && checkmark('left')}
            </GeneralText>
          </Group>
        )}
      />
      <Group isCentered isVertical>
        <Title isLarge>{stats.value}</Title>
        <GeneralText>{stats.label}</GeneralText>
      </Group>
      <ToggleContainer
        name={overName}
        checked={currentSelection === 'over'}
        onChange={handleChanges}
        render={({ isChecked }) => (
          <Group isVertical isCentered isVerticalCentered spaceSize={6}>
            <Icon
              color={isChecked ? colors.positive : colors.primary}
              size={24}>
              <ThumbsUpSolid />
            </Icon>
            <GeneralText
              isSmall
              color={isChecked ? colors.positive : colors.primary}>
              <b>Over</b>
              {isChecked && checkmark('right')}
            </GeneralText>
          </Group>
        )}
      />
    </Group>
  );
};

OverUnderMechanics.defaultProps = {
  stats: {
    label: '-----',
    value: '---',
  },
  selected: null,
  onChange: () => {},
};

OverUnderMechanics.propTypes = {
  stats: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  selected: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  labelTop: PropTypes.bool,
  hasLabels: PropTypes.bool,
};

export default OverUnderMechanics;
