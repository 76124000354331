import React, { useContext } from 'react';

import { Button, Group } from 'atoms';
import { Fill } from 'atoms/Group';

import MFLogo from 'assets/media/images/meteor-logo-2.png';

import useUserData from 'hooks/useUserData';
import { MainContext } from 'containers/MainContainer';

import colors from 'assets/themes/colors';

const Footer = () => {
  const [session] = useUserData();
  const { signOut } = useContext(MainContext);
  const isAnonymous = session && session.isAnonymous;
  return (
    <Group
      isFullWidth
      isCentered
      style={{
        alignItems: 'flex-end',
        margin: '-15px -15px -24px',
        backgroundColor: colors.primary,
        borderTop: `4px solid ${colors.accent}`,
        width: 320,
      }}>
      <img
        src={MFLogo}
        alt="MF Logo"
        style={{
          display: 'block',
          margin: 5,
          width: 70,
        }}
      />
      <Fill />
      <Button
        isSecondary
        isNeutral
        isXSmall
        isOutlined
        onClick={signOut}
        style={{ margin: 15 }}>
        {!isAnonymous ? 'Sign out' : 'Login'}
      </Button>
    </Group>
  );
};

export default Footer;
