import { combineReducers } from 'redux';

import main from 'containers/MainContainer/reducer';
import gameType from 'containers/GameTypeSelection/reducer';
import teamsSelection from 'containers/TeamsSelection/reducer';
import gameplay from 'containers/Gameplay/reducer';
import submit from 'containers/SubmitGame/reducer';
import accountVerification from 'containers/AccountVerification/reducer';

const reducers = combineReducers({
  main,
  gameType,
  teamsSelection,
  gameplay,
  submit,
  accountVerification,
});

export default (state, action) => {
  if (action.type === 'RESET') state = undefined;
  return reducers(state, action);
};
