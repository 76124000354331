import React from 'react';
import PropTypes from 'prop-types';

import { navigate } from '@reach/router';

import { connect } from 'react-redux';
import * as actions from './actions';

import { GameTypeSelection } from 'organisms';
import Wrapper from './components/Wrapper';

import FootBallPlayer1 from 'assets/media/images/football-player-1.png';
import FootBallPlayer2 from 'assets/media/images/football-player-2.png';

import callCheckRegion from 'hooks/callCheckRegion';
import useAnalytics from 'hooks/useAnalytics';

import useGuidedTour from 'hooks/guidedTour';
import { gameTypeSelectionTour } from 'hooks/guidedTour/tours';

export const GameType = ({ selectGameType }) => {
  const [Alert, { isAvailable, toggleModal }] = callCheckRegion();
  const [Tour] = useGuidedTour({
    tourName: gameTypeSelectionTour.name,
    autoStart: true,
  });

  const { logEvent } = useAnalytics();

  return (
    <Wrapper>
      <Alert />
      <Tour
        steps={gameTypeSelectionTour.steps}
        continuous
        showSkipButton
        disableScrolling
      />
      <GameTypeSelection
        title="Over Under"
        description={
          'Will individual players from 2 teams that you select go "Over" or "Under" for pre-determined stats?'
        }
        backgroundSource={FootBallPlayer1}
        onSelect={isFree => {
          if (!isFree && !isAvailable) {
            toggleModal();
            return;
          }

          logEvent('paid_free', { item_name: isFree ? 'Free' : 'Paid' });
          logEvent('gametype', { item_name: 'Over/Under' });

          selectGameType('overunder', isFree);
          navigate('/teams');
        }}
        css={{ minWidth: '100%', height: '50%' }}
      />
      <GameTypeSelection
        title="Statpicks"
        description={"Select two teams and predict the players' stats"}
        backgroundSource={FootBallPlayer2}
        onSelect={isFree => {
          if (!isFree && !isAvailable) {
            toggleModal();
            return;
          }

          logEvent('paid_free', { item_name: isFree ? 'Free' : 'Paid' });
          logEvent('gametype', { item_name: 'Statpicks' });

          selectGameType('statpicks', isFree);
          navigate('/teams');
        }}
        css={{ minWidth: '100%', height: '50%' }}
      />
    </Wrapper>
  );
};

GameType.propTypes = {
  selectGameType: PropTypes.func,
};

export default connect(
  store => ({
    ...store.gameType,
  }),
  { ...actions }
)(GameType);
