import { eventChannel } from 'redux-saga';
import { takeLatest, put, take, call, all } from 'redux-saga/effects';

import { fetch } from 'services/firebase';

import { groupBy } from 'services/utilities';

function* fetchAvailableTeams({ filter }) {
  const createChannel = () =>
    eventChannel(emit => {
      fetch
        .competitionLineups(filter, emit)
        .catch(error => emit(new Error(error)));
      return () => {};
    });
  const channel = yield call(createChannel);

  while (true) {
    try {
      const response = yield take(channel);
      const dayDivisors = ['Early', 'Afternoon', 'Late'];
      const availableTeams = groupBy(response, 'timeBlock.dayDivisor');
      const dayDivisorsKeys = Object.keys(availableTeams) || [];
      yield all([
        put({
          type: 'FETCH_AVAILABLE_TEAMS_SUCCESS',
          payload: availableTeams,
        }),
        put({
          type: 'SET_DAY_DIVISORS',
          payload: dayDivisors.filter(d => dayDivisorsKeys.indexOf(d) !== -1),
        }),
      ]);
    } catch (error) {
      yield put({ type: 'FETCH_AVAILABLE_TEAMS_ERROR', payload: error });
    }
  }
}

export default function* root() {
  yield all([takeLatest('FETCH_AVAILABLE_TEAMS', fetchAvailableTeams)]);
}
