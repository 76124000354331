import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Group, Icon, GeneralText } from 'atoms';
import { InfoCircle, CheckCircle } from 'assets/icons';
import colors from 'assets/themes/colors';

import useUserData from 'hooks/useUserData';

const Content = ({ onClose }) => {
  const [session] = useUserData();

  const [status, setStatus] = useState('waiting');

  const isVerified = session.emailVerified;

  useEffect(() => {
    if (isVerified) setStatus('success');
    if (!isVerified) setStatus('error');
    return () => {
      onClose();
    };
  }, []);

  let message = '';
  let color = null;
  let icon = <InfoCircle />;

  if (status === 'processing') message = 'Sending...';
  if (status === 'error') message = 'Please, check your inbox first.';

  if (status === 'success') {
    message = 'Email verified!';
    color = colors.positive;
    icon = <CheckCircle />;
  }

  return (
    <Group
      isVertical
      isVerticalCentered
      isCentered
      spaceSize={15}
      padding={5}
      style={{ height: 90 }}>
      <Icon size={46}>{icon}</Icon>
      <GeneralText color={color}>
        <b>{message}</b>
      </GeneralText>
    </Group>
  );
};

Content.propTypes = {
  onClose: PropTypes.func,
};

export default Content;
