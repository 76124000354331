import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import PlayerCard from 'organisms/PlayerCard';

import Swiper from 'atoms/Swiper';
import Button from 'atoms/Button';
import Icon from 'atoms/Icon';
import GeneralText from 'atoms/GeneralText';

import CardWrapper from 'molecules/CardWrapper';

import { AngleLeft, AngleRight, ThumbsUp } from 'assets/icons';
import colors from 'assets/themes/colors';

import StatpicksMechanics from 'molecules/StatpicksMechanics';
import Modal from 'molecules/Modal';
import InputValueCard from 'molecules/StatpicksMechanics/InputValueCard';

const CardsSwiperStatpicks = ({
  onChangeCard,
  onClickLastCard,
  onSelectOption,
  data,
  currentIndex,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [modalValue, setModalValue] = useState({});
  const [modalProps, setModalProps] = useState(null);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMoving, setMovingState] = useState(false);

  useEffect(() => {
    if (currentIndex != null && currentIndex != currentSlide)
      setCurrentSlide(currentIndex);
  }, [currentIndex]);

  const styles = {
    wrapper: {
      padding: '0 13px',
    },
    slideStyle: {
      padding: '5px 4px',
    },
  };

  return (
    <div
      data-guided-tour="card-swiper-statpicks"
      style={{ width: '100%', position: 'relative' }}>
      <div>
        {currentSlide !== 0 && currentSlide !== data.length && (
          <Button
            isBare
            onClick={
              currentSlide > 0
                ? () => {
                    setMovingState(true);
                    setCurrentSlide(currentSlide - 1);
                  }
                : null
            }
            style={{
              position: 'absolute',
              opacity: isMoving ? 0.4 : 1,
              zIndex: 5,
              top: 150,
              left: 60,
            }}>
            <Icon size={46}>
              <AngleLeft />
            </Icon>
          </Button>
        )}

        {currentSlide !== data.length && (
          <Button
            data-guided-tour="arrow-right-statpicks"
            isBare
            onClick={
              currentSlide < data.length
                ? () => {
                    setMovingState(true);
                    setCurrentSlide(currentSlide + 1);
                  }
                : null
            }
            style={{
              position: 'absolute',
              opacity: isMoving ? 0.4 : 1,
              zIndex: 5,
              top: 150,
              right: 60,
            }}>
            <Icon size={46}>
              <AngleRight />
            </Icon>
          </Button>
        )}

        <Modal
          isOpen={isOpen}
          onClickToClose={() => {
            setModalProps(null);
            setOpen(false);
          }}>
          {isOpen && modalProps && (
            <InputValueCard
              {...modalProps}
              cardDescription={`What will be the number of ${modalProps.stats.label}?`}
              inputLabel={modalProps.stats.label}
              onConfirm={value => {
                setOpen(false);
                setModalProps(null);
                setModalValue({ ...modalValue, [modalProps.id]: value });
              }}
            />
          )}
        </Modal>

        <Swiper
          enableMouseEvents
          style={styles.wrapper}
          slideStyle={styles.slideStyle}
          index={currentSlide}
          onChangeIndex={current => {
            setCurrentSlide(current);
            setMovingState(true);
          }}
          onTransitionEnd={() => {
            setMovingState(false);
            onChangeCard(currentSlide, data[currentSlide]);
          }}>
          {data.map((cardProps, index) => (
            <PlayerCard
              {...cardProps}
              stats={cardProps.projections.statpicks}
              isActive={index === currentSlide && !isMoving}
              key={cardProps.id}>
              <StatpicksMechanics
                stats={cardProps.stats}
                updatedValue={modalValue[cardProps.id] || cardProps.selected}
                onClickValue={() => {
                  setOpen(true);
                  setModalProps(cardProps);
                }}
                onChange={(name, value) => {
                  onSelectOption({
                    cardId: cardProps.id,
                    selection: value,
                  });
                }}
              />
            </PlayerCard>
          ))}

          <CardWrapper
            topLabel={<GeneralText color={colors.white}>&nbsp;</GeneralText>}
            sx={{ paddingBottom: 25, height: 460 }}>
            <div
              style={{
                height: 400,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}>
              <Icon size={100} color={colors.secondary}>
                <ThumbsUp />
              </Icon>
              <GeneralText
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  textAlign: 'center',
                }}>
                <b>
                  Finished making your predictions?
                  <br />
                  Submit your entry now!
                </b>
              </GeneralText>
              {!!onClickLastCard && (
                <Button isSmall onClick={onClickLastCard}>
                  SUBMIT
                </Button>
              )}
            </div>
          </CardWrapper>
        </Swiper>
      </div>
    </div>
  );
};

CardsSwiperStatpicks.defaultProps = {
  data: [],
  onChangeCard: () => {},
  onSelectOption: () => {},
};

CardsSwiperStatpicks.propTypes = {
  currentIndex: PropTypes.number,
  data: PropTypes.array,
  onChangeCard: PropTypes.func,
  onClickLastCard: PropTypes.func,
  onSelectOption: PropTypes.func,
};

export default CardsSwiperStatpicks;
