import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { auth } from 'services/firebase';

export const SessionContext = React.createContext({});

const SessionManager = ({ children }) => {
  const [currentUser, updateCurrentUser] = useState(null);
  const [isReady, updateStatus] = useState(null);

  const timeout = setTimeout;

  const reloadSession = () => {
    return new Promise((resolve, reject) => {
      if (auth() && auth().currentUser) {
        auth()
          .currentUser.reload()
          .then(() => {
            updateCurrentUser({
              ...auth().currentUser.toJSON(),
              state: auth().currentUser,
            });
            timeout(resolve, 100);
          })
          .catch(reject);
      }
    });
  };

  useEffect(() => {
    auth().onAuthStateChanged(currentState => {
      if (!currentState) {
        auth()
          .signInAnonymously()
          .then();
      }
      if (currentState) {
        updateCurrentUser({
          ...currentState.toJSON(),
          state: auth().currentUser,
        });
        updateStatus(true);
      }
    });
    return () => {
      clearTimeout(timeout);
      const hasCurrentAnonymousUser =
        auth() && auth().currentUser && auth().currentUser.isAnonymous;
      if (hasCurrentAnonymousUser) auth().currentUser.delete();
    };
  }, []);

  return (
    <SessionContext.Provider
      value={{
        reloadSession,
        session: currentUser,
        isAnonymous: (currentUser || {}).isAnonymous,
        isReady,
      }}>
      {children}
    </SessionContext.Provider>
  );
};

SessionManager.propTypes = {
  children: PropTypes.any.isRequired,
};

export default SessionManager;
