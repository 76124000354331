import React from 'react';
import PropTypes from 'prop-types';

import colors from 'assets/themes/colors';
import { InfoCircleSolid, Times } from 'assets/icons';
import { Button, Group, Icon, Title } from 'atoms';
import { HeaderContainer, StatsRow } from 'molecules';
import { BasicTextCard, PlayerProfileHeader } from 'organisms';

const PlayerProfile = ({
  teamName,
  recentGames,
  personalInfo,
  personalStats,
  fullName,
  totalStats,
  teamLogoUrl,
  picture,
  position,
  playerNumber,
}) => (
  <>
    <HeaderContainer
      leftComponent={
        <Button isBare /*onClick={}*/>
          <Icon size={24} color={colors.white}>
            <Times />
          </Icon>
        </Button>
      }
      centerComponent={
        <Title isSmall color={colors.white}>
          {teamName}
        </Title>
      }
      rightComponent={
        <Button isBare /*onClick={displayInfo}*/>
          <Icon size={24} color={colors.white}>
            <InfoCircleSolid />
          </Icon>
        </Button>
      }
    />
    <PlayerProfileHeader
      teamName={teamName}
      fullName={fullName}
      teamLogoUrl={teamLogoUrl}
      picture={picture}
      position={position}
      playerNumber={playerNumber}></PlayerProfileHeader>
    <StatsRow stats={totalStats} hasLabelOnTop />
    <div css={{ backgroundColor: colors.white, height: '51px' }} />
    <Group
      isVertical
      isCentered
      padding={20}
      spaceSize={20}
      css={{
        position: 'relative',
        top: '-51px',
      }}>
      <BasicTextCard
        title={'PROFILE'}
        data={personalInfo}
        additionalData={personalStats}
      />

      <BasicTextCard title={'RECENT GAMES'} data={recentGames} />
    </Group>
  </>
);

PlayerProfile.defaultProps = {
  personalStats: [],
  personalInfo: [],
  recentGames: [],
  totalStats: [],
};

PlayerProfile.propTypes = {
  personalStats: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  personalInfo: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  recentGames: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  totalStats: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  teamName: PropTypes.string,
  teamLogoUrl: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  picture: PropTypes.string,
  position: PropTypes.string.isRequired,
  playerNumber: PropTypes.number.isRequired,
};

export default PlayerProfile;
