import React from 'react';

import callEmailVerification from 'hooks/callEmailVerification';

import Flag from './components/Flag';

const useEmailStatus = show => {
  const [EmailVerification, verify, resend] = callEmailVerification();

  const Render = () => {
    return (
      <>
        <EmailVerification />
        {show && <Flag onClickToResend={resend} onClickToVerify={verify} />}
      </>
    );
  };

  return Render;
};

export default useEmailStatus;
