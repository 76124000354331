import React from 'react';
import PropTypes from 'prop-types';

import List from 'atoms/List';
import Group, { Fill } from 'atoms/Group';
import GeneralText from 'atoms/GeneralText';
import Badge from 'atoms/Badge';
import Space from 'atoms/Space';

import InlinePlayerCard from 'organisms/InlinePlayerCard';

const CardsSummary = ({ data, teamNumber, onClickEdit, onClickPlayerInfo }) => {
  return (
    <Group isVertical>
      <Group isFullWidth isVerticalCentered>
        <GeneralText isMedium>
          <strong>Team</strong>
        </GeneralText>
        <Fill />
        <Badge isLarge isDanger={teamNumber === 2}>
          <GeneralText isLarge color={'white'}>
            <strong>{teamNumber}</strong>
          </GeneralText>
        </Badge>
      </Group>
      <Space isMedium isVertical />
      <List>
        {data.map((item, index) =>
          item.status === 'BACKUP' ? (
            <>
              <GeneralText
                isSmall
                style={{
                  textAlign: 'center',
                  maxWidth: '80%',
                  margin: 'auto',
                }}>
                A backup player counts if one of the lineup players is unable to
                compete.
              </GeneralText>
              <Space isVertical size={12} />
              <InlinePlayerCard
                {...item}
                isBordered
                isBackup
                onClickEdit={() => onClickEdit(item)}
                onClickPlayerInfo={() => onClickPlayerInfo(item)}
                key={index}
              />
            </>
          ) : (
            <InlinePlayerCard
              {...item}
              onClickEdit={() => onClickEdit(item)}
              onClickPlayerInfo={() => onClickPlayerInfo(item)}
              key={index}
            />
          )
        )}
      </List>
    </Group>
  );
};

CardsSummary.defaultProps = {
  data: [],
  onClickEdit: () => {},
  onClickPlayerInfo: () => {},
};

CardsSummary.propTypes = {
  data: PropTypes.array.isRequired,
  onClickEdit: PropTypes.func,
  onClickPlayerInfo: PropTypes.func,
  teamNumber: PropTypes.number,
};

export default CardsSummary;
