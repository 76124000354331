import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { Button, Icon, Group, ImageWrapper, GeneralText } from 'atoms';
import { ArrowLeft } from 'assets/icons';

import ToggleSwitch from 'molecules/ToggleSwitch';
import Alert from 'molecules/Alert';
import useGameSteps from 'hooks/useGameSteps';

import { MainContext } from 'containers/MainContainer';

import NFLLogo from 'assets/media/logos/nfl/NFL.svg';

import { toggleGameType } from '../helpers';
import useFriendlyName from 'services/friendlyNames';
import { useAppContainer } from 'organisms/AppContainer';

const Wrapper = ({ children, isLoading, gameType, onChangeGameType }) => {
  const { resetGame } = useContext(MainContext);

  const { reload } = useAppContainer({
    header: {
      renderLeft: (
        <Group isVerticalCentered spaceSize={6}>
          <Button isBare onClick={() => window.history.back()}>
            <Icon size={16} color="#FFF">
              <ArrowLeft />
            </Icon>
          </Button>
          <Button isBare onClick={() => window.history.back()}>
            <ImageWrapper source={NFLLogo} alt={'NFL Logo'} height={42} />
          </Button>
        </Group>
      ),
      renderCenter: (
        <ToggleSwitch
          initialChecked={gameType}
          onGameChange={() => setAlertState(true)}
        />
      ),
    },
  });

  useEffect(() => {
    reload();
  }, [gameType]);

  const isAvailableToPlay =
    useGameSteps(['timeBlock', 'entryFees']) &&
    isLoading !== null &&
    !isLoading;

  const [showAlert, setAlertState] = useState(false);

  const currentGameType = useFriendlyName(gameType);
  const nextGameType = useFriendlyName(toggleGameType[gameType]);

  return (
    <div style={{ maxWidth: 450, margin: 'auto' }}>
      <Alert
        isOpen={showAlert}
        title="Important!"
        onClickToCancel={() => setAlertState(false)}
        onClickToProceed={() => {
          setAlertState(false);
          onChangeGameType();
        }}
        onClickToClose={() => setAlertState(false)}>
        <GeneralText>
          You are about to change the type of the game from{' '}
          <b>{currentGameType}</b> to <b>{nextGameType}</b> and will lose your
          current predictions.
        </GeneralText>
      </Alert>

      <Alert
        isOpen={!isAvailableToPlay && isLoading !== null && !isLoading}
        disableClose
        title="Contest unavailable!"
        cancelButtonLabel="Restart"
        proceedButtonLabel={'Try ' + nextGameType}
        onClickToCancel={resetGame}
        onClickToProceed={onChangeGameType}>
        <GeneralText>
          It looks like you’ve already entered this contest.
        </GeneralText>
      </Alert>

      {isLoading === null || isLoading ? null : isAvailableToPlay && children}
    </div>
  );
};

Wrapper.propTypes = {
  children: PropTypes.any,
  gameType: PropTypes.string,
  isAvailableToPlay: PropTypes.bool,
  isLoading: PropTypes.bool,
  onChangeGameType: PropTypes.func,
};

export default Wrapper;
