/* eslint-disable react/prop-types */
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/core';

import colors from 'assets/themes/colors';

import { Group, Title, Button, Icon } from 'atoms';
import { Times } from 'assets/icons';

const slideUpSoft = keyframes`
  0% {
    opacity: .1;
    transform-origin: center;
    transform: translateY(4%);
  }
  100% {
    opacity: .99;
    transform-origin: center;
    transform: translateY(0);
  }
`;

const fadeIn = keyframes`
  0% { opacity: .1; }
  100% { opacity: .99; }
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 100;
  ${({ isOpen }) =>
    isOpen
      ? css`
          height: 100%;
          opacity: 1;
          width: 100%;
          ${Background} {
            opacity: 1;
          }
          ${Content} {
            animation: ${slideUpSoft} 0.3s ease-in-out;
            opacity: 1;
          }
        `
      : css`
          height: 0;
          opacity: 0;
          width: 0;
        `}
`;

const Header = styled.div`
  align-items: center;
  z-index: 100;
  background-color: ${colors.accent};
  display: flex;
  flex-shrink: 0;
  height: 42px;
  justify-content: space-between;
  padding: 10px 15px 8px;
`;

const Content = styled.div`
  background-color: ${colors.white};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 220px;
  opacity: 0;
  position: absolute;
  width: 300px;
  z-index: 100;
`;

const Body = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px 15px;
`;

const Footer = styled.div`
  padding: 15px;
`;

const Background = styled.div`
  animation: ${fadeIn} 0.3s ease-in-out;
  background-color: rgba(0, 0, 0, 0.6);
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
`;

const AlertBody = ({
  isOpen,
  children,
  disableClose,
  title,
  onClickToClose,
  onClickToCancel,
  hideCancelButton,
  onClickToProceed,
  hideProceedButton,
  cancelButtonLabel,
  proceedButtonLabel,
}) => (
  <Wrapper isOpen={isOpen}>
    <Content>
      <Header>
        <Title color={colors.white} isSmall>
          {title}
        </Title>
        {!disableClose && (
          <Button
            onClick={onClickToClose}
            style={{ padding: 0, background: 'none' }}>
            <Icon size={20} color={colors.white}>
              <Times />
            </Icon>
          </Button>
        )}
      </Header>
      <Body>{children}</Body>
      {(!hideCancelButton || !hideProceedButton) && (
        <Footer>
          <Group
            isFullWidth
            spaceSize={10}
            style={{ justifyContent: 'flex-end' }}>
            {!hideCancelButton && (
              <Button isSecondary isOutlined isSmall onClick={onClickToCancel}>
                {cancelButtonLabel}
              </Button>
            )}
            {!hideProceedButton && (
              <Button isOutlined isSmall onClick={onClickToProceed}>
                {proceedButtonLabel}
              </Button>
            )}
          </Group>
        </Footer>
      )}
    </Content>
    <Background onClick={!disableClose ? onClickToClose : null} />
  </Wrapper>
);

AlertBody.defaultProps = {
  cancelButtonLabel: 'Cancel',
  proceedButtonLabel: 'Proceed',
};

AlertBody.propTypes = {
  children: PropTypes.any,
  disableClose: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClickToClose: PropTypes.func,
};

const body = document.body;

class Portal extends React.PureComponent {
  componentDidMount() {
    body.appendChild(this.element);
  }
  componentWillUnmount() {
    body.removeChild(this.element);
  }
  render() {
    // eslint-disable-next-line react/destructuring-assignment
    return ReactDOM.createPortal(this.props.children, this.element);
  }
  element = document.createElement('div');
}

const Alert = props => (
  <Portal>
    <AlertBody {...props} />
  </Portal>
);

Alert.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

Alert.Content = styled.div`
  flex: 1;
  max-height: 100%;
  overflow-y: auto;
  padding: 20px;
`;

Alert.Footer = styled.div`
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  width: 100%;
`;

export default Alert;
