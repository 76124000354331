import { takeLatest, put, call, all, select } from 'redux-saga/effects';

import { api } from 'services/firebase';

function* submitContest({ successCallback, token }) {
  try {
    const store = yield select(({ main, teamsSelection, gameplay }) => ({
      main,
      teamsSelection,
      gameplay,
    }));

    const { teams, competitors } = store.teamsSelection;
    const { selectedEntryFee, predictions } = store.gameplay;

    const predictionsMap = {};

    const parsePrediction = value => {
      if (value === 'over') return 1;
      if (value === 'under') return -1;
      return value;
    };

    competitors.forEach(player => {
      predictionsMap[player.id] = {
        [player.stats.name]: {
          metric: player.stats.name,
          metricPrediction: parsePrediction(predictions[player.id]),
          gameType: selectedEntryFee.gameType,
        },
      };
    });

    const body = {
      competitionLineupIds: [teams[0].id, teams[1].id],
      entryFeeInstance: { id: selectedEntryFee.id },
      timeBlockId: teams[0].timeBlockId,
      predictions: predictionsMap,
    };

    const response = yield call(api.createEntry, body, token);

    if (!response.success) {
      yield put({ type: 'SUBMIT_CONTEST_ERROR', payload: response.feedback });
      return;
    }

    if (successCallback && typeof successCallback === 'function')
      successCallback(response);

    yield put({ type: 'SUBMIT_CONTEST_SUCCESS', payload: response });
  } catch (error) {
    yield put({ type: 'SUBMIT_CONTEST_ERROR', payload: error.feedback });
  }
}

export default function* root() {
  yield all([takeLatest('SUBMIT_CONTEST', submitContest)]);
}
