import React from 'react';
import PropTypes from 'prop-types';

import { Container } from 'atoms';

const ContainerWithBackground = ({
  source,
  height,
  children,
  style,
  ...rest
}) => (
  <Container
    height={height}
    style={{
      ...style,
      background: `url(${String(source)}) no-repeat bottom center`,
      backgroundSize: 'cover',
    }}
    {...rest}>
    {children}
  </Container>
);

ContainerWithBackground.defaultProps = {
  height: '100%',
};

ContainerWithBackground.propTypes = {
  children: PropTypes.node,
  height: PropTypes.string,
  source: PropTypes.string,
  style: PropTypes.object,
};

export default ContainerWithBackground;
