import React from 'react';

import moment from 'moment';
import 'moment-timezone';

const OldTimeBlocks = ({ onChange }) => {
  const dates = diff => {
    if (diff == 0) return {};

    const start = moment()
      .tz('America/New_York')
      .isoWeekday(-1)
      .startOf('isoWeek')
      .add(1, 'day')
      .subtract(diff, 'week');

    const end = moment(start)
      .tz('America/New_York')
      .isoWeekday(0)
      .endOf('isoWeek')
      .add(1, 'week');

    return { start: start.toDate(), end: end.toDate() };
  };
  return (
    <select
      style={{
        width: 'calc(100% - 30px)',
        border: '1px solid #CCC',
        margin: 15,
        padding: 10,
        fontSize: 12,
        backgroundColor: 'white',
      }}
      defaultValue={0}
      onChange={({ target }) => onChange(dates(target.value))}>
      <option value={4}>Current -4</option>
      <option value={3}>Current -3</option>
      <option value={2}>Current -2</option>
      <option value={1}>Current -1</option>
      <option value={0}>Current</option>
    </select>
  );
};

export default OldTimeBlocks;
