import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import colors from 'assets/themes/colors';
import { Group, GeneralText, VerticalDivider } from 'atoms';

const StatsRow = ({ stats, hasLabelOnTop }) => (
  <Group padding={10} isCentered>
    {stats.map(
      (item, index) =>
        index < stats.length && (
          <Fragment key={index}>
            {index > 0 && <VerticalDivider height={36} />}
            <Group
              isFullWidth
              isVertical
              isCentered
              style={{
                flexDirection: hasLabelOnTop ? 'column-reverse' : 'column',
              }}>
              <GeneralText isMedium>
                <b>{item.value}</b>
              </GeneralText>
              <GeneralText isSmall isTruncated color={colors.black}>
                {item.label}
              </GeneralText>
            </Group>
          </Fragment>
        )
    )}
  </Group>
);

StatsRow.defaultProps = {
  stats: [],
};

StatsRow.propTypes = {
  stats: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
  hasLabelOnTop: PropTypes.bool,
};

export default StatsRow;
