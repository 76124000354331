import React, { useState } from 'react';

import { navigate } from '@reach/router';

import colors from 'assets/themes/colors';

import {
  Button,
  FormContainer,
  GeneralText,
  Group,
  Icon,
  Input,
  Title,
  ImageWrapper,
  Space,
} from 'atoms';

import { Fill } from 'atoms/Group';
import Alert from 'molecules/Alert';
import MeteorLogo from 'assets/media/images/meteor-logo-2@2x.png';
import { Envelope, LockAlt } from 'assets/icons';

import { auth } from 'services/firebase';

import { returnTo } from 'services/utilities';

import useAnalytics from 'hooks/useAnalytics';

const SignIn = () => {
  const [feedback, setFeedback] = useState('');

  const { logEvent } = useAnalytics();

  return (
    <Group
      isVertical
      isFullWidth
      isCentered
      isVerticalCentered
      style={{ margin: 'auto', maxWidth: 415 }}>
      <Group isVertical isFullWidth isCentered spaceSize={20} padding={30}>
        <Space isLarge isVertical />
        <ImageWrapper source={MeteorLogo} height={180} />
        <Title color={colors.white}>Sign In</Title>

        <FormContainer
          onSubmit={({ email, password }) => {
            auth()
              .signInWithEmailAndPassword(email, password)
              .then(() => {
                logEvent('login');
                navigate(`/${returnTo() || 'gametype'}`);
              })
              .catch(() =>
                setFeedback(
                  'Your username or password is incorrect, please try again!'
                )
              );
          }}
          render={({ values, handleChange, handleSubmit }) => {
            return (
              <Group isFullWidth isVertical spaceSize={20} isCentered>
                <Input
                  leftIcon={
                    <Icon
                      color={colors.accent}
                      size={18}
                      style={{ zIndex: 10 }}>
                      <Envelope />
                    </Icon>
                  }
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  placeholder="Email"
                />
                <Input
                  leftIcon={
                    <Icon
                      color={colors.accent}
                      size={18}
                      style={{ zIndex: 10 }}>
                      <LockAlt />
                    </Icon>
                  }
                  name="password"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  placeholder="Password"
                />
                <Button type="submit" isFullWidth onClick={handleSubmit}>
                  ENTER
                </Button>
              </Group>
            );
          }}
        />

        <Group isFullWidth>
          <Button isBare onClick={() => navigate('/auth/password-recovery')}>
            <GeneralText color={colors.white}>Forgot password</GeneralText>
          </Button>
          <Fill />
          <Button isBare onClick={() => navigate('/auth/signup')}>
            <GeneralText color={colors.accent}>Create account</GeneralText>
          </Button>
        </Group>
      </Group>

      <Fill />

      <Alert
        isOpen={!!feedback}
        disableClose
        title="Something happened"
        cancelButtonLabel="Sign up"
        proceedButtonLabel={'Try again'}
        onClickToCancel={() => navigate('/auth/signup')}
        onClickToProceed={() => setFeedback('')}>
        <GeneralText>{feedback}</GeneralText>
      </Alert>
    </Group>
  );
};

SignIn.propTypes = {};

export default SignIn;
