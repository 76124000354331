import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import GeneralText from 'atoms/GeneralText';
import colors from 'assets/themes/colors';

const Wrapper = styled.div`
  display: inline-flex;
  padding: ${({ isSmall }) => (isSmall ? '2px 6px' : '4px 12px')};
  border-radius: 3px;
  background-color: ${({
    color,
    isDanger,
    isPrimary,
    isSecondary,
    isSuccess,
  }) => {
    if (isDanger) color = colors.alert;
    if (isPrimary) color = colors.accent;
    if (isSecondary) color = colors.primary;
    if (isSuccess) color = colors.positive;
    return color;
  }};
`;

const Badge = ({ children, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <GeneralText color={colors.white} isSmall>
        {children}
      </GeneralText>
    </Wrapper>
  );
};

Badge.defaultProps = {
  color: colors.accent,
};

Badge.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  isDanger: PropTypes.bool,
  isPrimary: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isSmall: PropTypes.bool,
  isSuccess: PropTypes.bool,
};

export default Badge;
