import { eventChannel } from 'redux-saga';
import { takeLatest, put, take, call, all, select } from 'redux-saga/effects';

import { fetch } from 'services/firebase';

import { compareArrayOfObjects } from 'services/utilities';

function* fetchEntryFees({ timeBlockId, gameType, modality }) {
  const createChannel = () =>
    eventChannel(emit => {
      fetch
        .entryFeesInstances({ timeBlockId, gameType, modality }, emit)
        .catch(error => emit(new Error(error)));
      return () => {};
    });
  const channel = yield call(createChannel);
  while (true) {
    try {
      let response = yield take(channel);

      const selectedEntryFee = yield select(
        state => state.gameplay.selectedEntryFee
      );
      const competitors = yield select(
        state => state.teamsSelection.competitors
      );
      const predictions = yield select(state => state.gameplay.predictions);

      const userId = yield select(state => (state.main.userData || {}).uid);

      // Filter instances to check if this user already had played
      response = response.map(instance => {
        if (instance.usersWithEntries[userId] || instance.full)
          instance.isDisabled = true;
        return instance;
      });

      const defaultInstance = response
        .sort(compareArrayOfObjects('entryFee'))
        .filter(e => !e.isDisabled)[0];

      const currentIsUsed =
        selectedEntryFee &&
        response.filter(e => selectedEntryFee.id == e.id && !e.isDisabled)[0];

      const effects = [];

      if (defaultInstance && !selectedEntryFee) {
        effects.push(
          put({ type: 'SELECT_ENTRY_FEE', payload: defaultInstance })
        );
      }

      if (!predictions || (!Object.keys(predictions).length && competitors)) {
        const defaultPredictions = {};
        competitors.forEach(p => (defaultPredictions[p.id] = null));
        effects.push(
          put({ type: 'SETUP_PREDICTIONS', payload: defaultPredictions })
        );
      }

      if (defaultInstance && selectedEntryFee && !currentIsUsed) {
        effects.push(
          put({ type: 'SELECT_ENTRY_FEE', payload: defaultInstance })
        );
      }

      effects.push(
        put({ type: 'FETCH_ENTRY_FEES_SUCCESS', payload: response })
      );

      yield all(effects);
    } catch (error) {
      yield put({ type: 'FETCH_ENTRY_FEES_ERROR', payload: error });
    }
  }
}

export default function* root() {
  yield all([takeLatest('FETCH_ENTRY_FEES', fetchEntryFees)]);
}
