import React from 'react';
import PropTypes from 'prop-types';

import HeaderContainer from 'molecules/HeaderContainer';

import { Avatar, Button, GeneralText } from 'atoms';

import { gravatar } from 'services/utilities';
import useUserData from 'hooks/useUserData';

import MFLogo from 'assets/media/images/meteor-logo-2.png';

const AppHeader = ({
  title,
  renderLeft,
  renderCenter,
  onClickAvatar,
  drawerIsOpen,
}) => {
  const [session] = useUserData();

  const isAnonymous = session && session.isAnonymous;
  const email = session && session.email;
  const photoURL = session.photoURL;

  let avatarSource = photoURL || (email && gravatar(email));

  if (isAnonymous) avatarSource = MFLogo;

  return (
    <HeaderContainer
      leftComponent={renderLeft}
      centerComponent={
        title ? (
          <GeneralText color="#FFFFFF">{title}</GeneralText>
        ) : (
          renderCenter
        )
      }
      rightComponent={
        <Button isBare onClick={onClickAvatar}>
          <Avatar
            size={38}
            style={{ border: 'none' }}
            source={
              !drawerIsOpen
                ? avatarSource
                : 'https://icon-library.net/images/svg-close-icon/svg-close-icon-27.jpg'
            }
          />
        </Button>
      }
    />
  );
};

AppHeader.propTypes = {
  title: PropTypes.string,
  renderLeft: PropTypes.any,
  renderCenter: PropTypes.any,
  onClickAvatar: PropTypes.func,
  drawerIsOpen: PropTypes.bool,
  showBackButton: PropTypes.bool,
};

export default AppHeader;
