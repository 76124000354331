export default {
  name: 'teamSelectionTour',
  steps: [
    {
      target: 'body',
      title: 'Team Selection',
      content:
        "Playing a contest is easy, but there are a few things you need to know. Let's get started.",

      placement: 'center',
    },
    {
      target: '[data-guided-tour="day-divisor"]',
      content:
        'Based on the time the teams are playing, the games are separated by start time (Early, Afternoon, or Late)',
    },
    {
      target: '[data-guided-tour="team-select-1"]',
      title: 'Team 1 Selection',
      content:
        'Teams playing during the timeframe you selected show up here! You can toggle between your two teams using these buttons.',
    },
    {
      target: '[data-guided-tour="team-select-2"]',
      title: 'Team 2 Selection',
      content:
        "After you've chosen your first team - choose your second team here! According to game rules, you cannot select two teams playing each other on the field. You'll notice that selected team opponents aren't available for selection.",
    },
    {
      target: '[data-guided-tour="team-avatar"]',
      title: 'Team Lineups',
      content:
        'Picking your team selection is easy. Just click on the team logo and you can see the selected lineup.',
    },
    {
      target: '[data-guided-tour="selected-team-lineup"]',
      title: 'Team Lineups',
      content:
        'Your selected team lineup shows up here. We will always show you who your team is playing against and whether they are home or away.',
    },
    {
      target: '[data-guided-tour="header-right-component"]',
      title: 'Side Drawer',
      content:
        'Access your side drawer to see your draft contests and any active entries that you have submitted',
    },
    {
      target: '[data-guided-tour="lets-play"]',
      title: "Let's Play!",
      content:
        "After you've selected both teams, this button becomes enabled and it's time to start making your predictions!",
    },
  ],
};
