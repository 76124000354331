// TODO: Enable lint rule after launch
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import colors from 'assets/themes/colors';

import { Button, Icon, Space, GeneralText, Avatar, Group } from 'atoms';
import { Fill } from 'atoms/Group';
import { ShieldCheck, UserCircle, PlusCircle } from 'assets/icons';

import { gravatar } from 'services/utilities';

import { navigate } from '@reach/router';

import useUserData from 'hooks/useUserData';
import callCheckRegion from 'hooks/callCheckRegion';

const AccountButton = styled(Button)`
  position: absolute;
  top: -5px;
  right: 0;
`;

const Balance = styled.div`
  position: absolute;
  top: -5px;
  left: 0;
`;

const UserInfo = ({ hideDrawer, onClickToAddFunds }) => {
  const [session] = useUserData();
  const isAnonymous = session && session.isAnonymous;
  const email = session && session.email;
  const photoURL = session.photoURL;
  const avatarSource = photoURL || (email && gravatar(email));

  const [Alert, { isAvailable, toggleModal }] = callCheckRegion();

  // TODO: Uncomment after launch
  // const goto = path => {
  //   hideDrawer();
  //   navigate(path);
  // };

  const isVerified = session.verified && !isAnonymous;

  const pathname = location.pathname.replace('/', '');

  if (isAnonymous)
    return (
      <Button
        isFullWidth
        isSmall
        onClick={() =>
          navigate(`/auth${pathname ? `?returnTo=${pathname}` : ''}`)
        }>
        Sign In or Join
      </Button>
    );

  return (
    <Group
      isVertical
      isFullWidth
      isVerticalCentered
      style={{ position: 'relative' }}>
      {isVerified && (
        <Balance>
          <Group spaceSize={10} isFullWidth>
            <Group isVertical isFullWidth>
              <GeneralText isSmall color={colors.tertiary}>
                <b>BALANCE</b>
              </GeneralText>
              <Group isVerticalCentered spaceSize={4}>
                <GeneralText isLarge>
                  <b>${session.promoCash + session.withdrawableFunds}</b>
                </GeneralText>
                <Button
                  isBare
                  onClick={() => {
                    if (isAvailable) onClickToAddFunds();
                    if (!isAvailable) toggleModal();
                  }}>
                  <Icon color={colors.positive} size={16}>
                    <PlusCircle />
                  </Icon>
                </Button>
              </Group>
            </Group>
          </Group>
        </Balance>
      )}

      <Alert />

      {/*
      TODO: Uncomment after launch
      <AccountButton isBare>
        <Group isVerticalCentered spaceSize={4}>
          <GeneralText isTruncated isSmall color={colors.tertiary}>
            ACCOUNT
          </GeneralText>
          <Icon size={13} color={colors.tertiary}>
            <UserCircle />
          </Icon>
        </Group>
      </AccountButton> */}

      <Avatar
        data-guided-tour="side-drawer-avatar"
        isPrimary={isVerified}
        isSecondary={!isVerified}
        source={avatarSource}
        size={80}
      />

      <Space isVertical size={5} />

      {session.firstName && session.lastName ? (
        <Group isVerticalCentered spaceSize={4}>
          <GeneralText isTruncated>
            <b>{session.firstName + ' ' + session.lastName}</b>
          </GeneralText>
          {isVerified && (
            <Icon size={12}>
              <ShieldCheck />
            </Icon>
          )}
        </Group>
      ) : null}
      <GeneralText isTruncated isSmall>
        {session.email}
      </GeneralText>

      {/* {!isVerified && ( TODO: Uncomment after launch
        <>
          <Space isVertical size={10} />
          <Button
            isFullWidth
            isXSmall
            isSecondary
            onClick={() => goto('/account-verification')}>
            Verify your account
          </Button>
        </>
      )} */}

      <Fill />
    </Group>
  );
};

UserInfo.propTypes = {
  hideDrawer: PropTypes.func,
  onClickToAddFunds: PropTypes.func,
  onClickToWithdraw: PropTypes.func,
};

export default UserInfo;
