import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { Router, createHistory } from '@reach/router';

import { ThemeProvider } from 'emotion-theming';
import { Global } from '@emotion/core';

import globalStyles from './assets/globalStyles';
import theme from './assets/themes/default';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from 'core/store';

import { initFirebase } from 'services/firebase';

import SessionManager from 'containers/SessionManager';
import MainContainer from 'containers/MainContainer';
import SignInFlow from 'containers/SignInFlow';

initFirebase();

let history = createHistory(window);

const timeout = setTimeout;

const App = () => {
  useEffect(() => {
    timeout(
      () =>
        document
          .querySelector('#splash-screen')
          .setAttribute('style', 'display: none;'),
      2000
    );
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Global styles={globalStyles} />
          <Router history={history}>
            <SessionManager path="/">
              <MainContainer path="/*" />
              <SignInFlow path="auth/*" />
            </SessionManager>
          </Router>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
