import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { toArrayWithIds } from './utilities';

import moment from 'moment';
import 'moment-timezone';

const db = firebase.firestore;

export default {
  competitionLineups: (filter, callback) =>
    new Promise((resolve, reject) => {
      const { start, end } = filter || {};

      // TODO: Implement others leagues later

      // TODO: Uncomment this when we have available teams in the current week
      // const startDate = moment()
      //   .isoWeekday(1)
      //   .startOf('day')
      //   .add(1, 'day')
      //   .toDate();

      // const endDate = moment(startDate)
      //   .isoWeekday(1)
      //   .endOf('day')
      //   .add(1, 'week')
      //   .toDate();

      const startDate = moment()
        .tz('America/New_York')
        .isoWeekday(-1)
        .startOf('isoWeek')
        .add(1, 'day')
        .toDate();

      const endDate = moment(startDate)
        .tz('America/New_York')
        .isoWeekday(1)
        .endOf('isoWeek')
        .add(1, 'week')
        .toDate();

      console.log('startDate: ' + (start || startDate));
      console.log('endDate: ' + (end || endDate));

      db()
        .collection(process.env.firestoreCompetitionsLineups)
        // .where('timeBlock.start', '>=', start || startDate)
        // .where('timeBlock.start', '<=', end || endDate)
        .onSnapshot(snapshot => {
          console.log(toArrayWithIds(snapshot));
          return (callback || resolve)(toArrayWithIds(snapshot));
        }, reject);
    }),
  entryFeesInstances: ({ timeBlockId, gameType, modality }, callback) =>
    new Promise((resolve, reject) => {
      if (!timeBlockId || !gameType || !modality) reject();
      const operator = modality === 'free' ? '==' : '>';
      db()
        .collection(process.env.firestoreEntryFessInstances)
        .where('timeBlockId', '==', timeBlockId)
        .where('gameType', '==', gameType)
        .where('entryFee', operator, 0)
        .onSnapshot(
          snapshot => (callback || resolve)(toArrayWithIds(snapshot)),
          reject
        );
    }),
};
