import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import colors from 'assets/themes/colors';
import { Group, GeneralText } from 'atoms';

const GroupWrapper = styled(Group)`
  color: ${colors.white};
  background-color: ${colors.black};
  border-radius: 40px;
  height: 32px;
  padding-left: 2px;
  padding-right: 2px;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 200px;
  margin: auto;
`;

const LabelWrapper = styled.label`
  cursor: pointer;
  display: flex;
  flex: 1;
  width: 100%;
  height: 38px;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  text-align: center;
`;

const TextWrapper = styled(GeneralText)`
  padding-left: 2px;
  padding-right: 2px;
  z-index: 1;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 2px;
  height: 28px;
  width: 120px;
  border-radius: 21px;
  background: ${colors.accent};
  transition: all 0.5s;
`;

const InputWrapper = styled.input`
  display: none;
`;

const ToggleSwitch = ({ items, initialChecked, onGameChange }) => {
  const checkedLabelIndex = items.findIndex(
    lbl => lbl.value === initialChecked
  );
  const width = 100 / items.length;

  const style = {
    left: `calc(${(100 / items.length) * checkedLabelIndex}% ${
      checkedLabelIndex == 0 ? '+' : '-'
    } 2px)`,
    width: width + '%',
  };

  const labelRef = useRef();

  return (
    <GroupWrapper isCentered isVerticalCentered>
      <></>
      {items.map((item, index) => {
        const isChecked = initialChecked === item.value;
        return (
          <LabelWrapper key={index} htmlFor={item.value}>
            <InputWrapper
              ref={index === 0 ? labelRef : null}
              onChange={e => {
                onGameChange(e.target.value);
              }}
              type="radio"
              id={item.value}
              value={item.value}
              checked={isChecked}
            />
            <TextWrapper
              isTruncated
              isSmall
              color={isChecked ? colors.white : colors.tertiary}>
              <b>{item.label.toUpperCase()}</b>
            </TextWrapper>
          </LabelWrapper>
        );
      })}
      <ButtonWrapper style={style} />
    </GroupWrapper>
  );
};

ToggleSwitch.defaultProps = {
  items: [
    { label: 'Over Under', value: 'overunder' },
    { label: 'Statpicks', value: 'statpicks' },
  ],
  onGameChange: () => {},
};

ToggleSwitch.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  initialChecked: PropTypes.string.isRequired,
  onGameChange: PropTypes.func,
};

export default ToggleSwitch;
