import React from 'react';

export default {
  name: 'gameTypeSelectionTour',
  steps: [
    {
      target: 'body',
      title: 'Welcome!',
      content:
        'Ready for a quick tour of Meteor Fantasy? Clicking skip ends the tour.',
      placement: 'center',
    },
    {
      target: '[data-guided-tour="header-left-component"]',
      title: 'NFL is just the start...',
      content: 'More sports will be launching soon! ',
    },
    {
      target: '[data-guided-tour="game-type"]',
      title: 'Contest Types',
      content: (
        <div>
          Where do you want to test your prediction skills? Over/Under? Or
          StatPicks? With StatPicks, you can test your accuracy at predicting
          your favorite player’s performance this weekend. Paid games are coming
          soon, but you can earn Meteor Dollars, our promotional currency, with
          our free contests that you can use in our paid contests when they go
          live!
        </div>
      ),
    },
    {
      target: '[data-guided-tour="select-free-contest"]',
      title: 'Free vs Paid',
      content:
        'Here! This is where those Free contests we were just talking about are!',
    },
    {
      target: '[data-guided-tour="links"]',
      title: 'Learn How to Play and more...',
      content:
        'Head to "How to Play" in our links section to get all the rules!',
    },
  ],
};
