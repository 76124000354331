import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { Image } from 'rebass';

import colors from 'assets/themes/colors';

import Icon from 'atoms/Icon';
import { UserAlt } from 'assets/icons';

const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 100%;
  border-style: ${({ isDashed }) => (isDashed ? 'dashed' : 'solid')};
  border-width: ${({ borderWidth }) => borderWidth}px;
  border-color: ${({ borderColor }) => borderColor};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  margin: auto;
  position: relative;
  padding: ${({ hasPadding, borderWidth }) => (hasPadding ? borderWidth : 0)}px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  background-color: ${colors.white};
  flex-shrink: 0;
  img {
    background-color: ${({ imageBackground }) => imageBackground};
    border-radius: 100%;
    width: auto;
    height: 100%;
  }
`;

const Avatar = ({
  imageBackground,
  hasPadding,
  isDashed,
  isDisabled,
  isMedium,
  isPrimary,
  isSecondary,
  isTertiary,
  isSmall,
  source,
  ...rest
}) => {
  let borderColor = colors.quaternary;

  if (isPrimary) borderColor = colors.accent;
  if (isSecondary) borderColor = colors.primary;
  if (isTertiary) borderColor = colors.white;

  let size = 150;
  let borderWidth = 3;

  if (isMedium) {
    size = 52;
    borderWidth = 2;
  }
  if (isSmall) {
    size = 42;
    borderWidth = 2;
  }

  return (
    <Wrapper
      imageBackground={imageBackground}
      hasPadding={hasPadding}
      borderColor={borderColor}
      borderWidth={borderWidth}
      isDashed={isDashed}
      size={size}
      isDisabled={isDisabled}
      {...rest}>
      {source ? (
        <Image src={source} css={{ backgroundColor: imageBackground }} />
      ) : (
        <Icon size={size / 2.5} color={colors.quaternary}>
          <UserAlt />
        </Icon>
      )}
    </Wrapper>
  );
};

Avatar.defaultProps = {
  imageBackground: 'white',
  hasPadding: true,
};

Avatar.propTypes = {
  imageBackground: PropTypes.string,
  hasPadding: PropTypes.bool,
  isDashed: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMedium: PropTypes.bool,
  isPrimary: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isTertiary: PropTypes.bool,
  isSmall: PropTypes.bool,
  source: PropTypes.string,
};

export default Avatar;
