import React, { useState } from 'react';
import { object, string } from 'yup';

import api from 'services/firebase/api';

import colors from 'assets/themes/colors';

import { Button, FormContainer, GeneralText, Group, Link, Space } from 'atoms';
import { ContainerWithBackground } from 'molecules';
import InputWrapper from './components/InputWrapper';
import WomanWithPhone from 'assets/media/images/woman-with-phone.png';
import { Envelope, Pencil, UserAlt } from 'assets/icons';

import useModal from 'hooks/useModal';
import useUserData from 'hooks/useUserData';

const validationSchema = object().shape({
  email: string().email('*E-mail is not valid'),
  message: string(),
  displayName: string(),
});

const Contact = () => {
  const [session] = useUserData();
  const [Modal, toggleModal] = useModal();

  const [submitMessage, setSubmitMessage] = useState('');
  const isAnonymous = session.isAnonymous;

  const initialValues = {
    displayName: isAnonymous ? '' : session.firstName + ' ' + session.lastName,
    email: isAnonymous ? '' : session.email,
    message: '',
  };

  return (
    <ContainerWithBackground
      source={WomanWithPhone}
      height="auto"
      style={{ minHeight: '100%' }}>
      <Group
        isVertical
        isFullWidth
        isCentered
        spaceSize={20}
        padding={30}
        style={{ height: 'auto' }}>
        <GeneralText isMedium color={colors.white}>
          <b>Have more questions?</b>
        </GeneralText>
        <GeneralText isMedium color={colors.white}>
          Contact us at{' '}
          <Link isMedium to="mailto:help@meteorfantasy.com">
            help@meteorfantasy.com
          </Link>
        </GeneralText>
        <GeneralText isMedium color={colors.white}>
          or call us at{' '}
          <Link isMedium to="tel:+18447275979">
            (844) 727-5979
          </Link>
        </GeneralText>
        <GeneralText isMedium color={colors.white}>
          Alternatively, you can use the following form. Our skilled staff will
          contact you within the next business day.
        </GeneralText>
        <FormContainer
          initialValues={initialValues}
          enableReinitialize
          onSubmit={({ email, message, displayName }, { resetForm }) => {
            api
              .sendEmail({
                from: email,
                text: `${message}\n\nfrom: ${displayName}`,
              })
              .then(
                () => {
                  resetForm(initialValues);
                  toggleModal();
                  setSubmitMessage('Message sent!');
                },
                () => {
                  toggleModal();
                  setSubmitMessage(
                    'There was an error on our server. Please try again.'
                  );
                }
              );
          }}
          validationSchema={validationSchema}
          render={({
            values,
            handleChange,
            handleSubmit,
            errors,
            touched,
            isValid,
            isSubmitting,
          }) => {
            const inputProps = { values, errors, touched, handleChange };
            const isReallyValid =
              isValid && values.displayName && values.email && values.message;
            return (
              <Group isFullWidth isVertical spaceSize={20} isCentered>
                <InputWrapper
                  iconSvg={<UserAlt />}
                  name="displayName"
                  disabled={!isAnonymous}
                  {...inputProps}
                  placeholder="Full name"
                />
                <InputWrapper
                  iconSvg={<Envelope />}
                  disabled={!isAnonymous}
                  name="email"
                  {...inputProps}
                />
                <InputWrapper
                  iconSvg={<Pencil />}
                  name="message"
                  isTextarea
                  type="message"
                  height="300px"
                  {...inputProps}
                />
                <Button
                  type="submit"
                  isFullWidth
                  isSmall
                  onClick={handleSubmit}
                  isDisabled={!isReallyValid || isSubmitting}>
                  SEND
                </Button>
                <Space isSmall />
              </Group>
            );
          }}
        />
      </Group>
      <Modal customHeader={() => null} isCovering={false}>
        {submitMessage}
      </Modal>
    </ContainerWithBackground>
  );
};

export default Contact;
