import * as firebase from 'firebase/app';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: process.env.firebaseApiKey,
  authDomain: process.env.firebaseAuthDomain,
  databaseURL: process.env.firebaseDatabaseURL,
  projectId: process.env.firebaseProjectId,
  storageBucket: process.env.firebaseStorageBucket,
  messagingSenderId: process.env.firebaseMessagingSenderId,
  appId: process.env.firebaseAppId,
  measurementId: process.env.measurementId,
};

export const initFirebase = () => {
  firebase.initializeApp(firebaseConfig);
  firebase.firestore().enablePersistence({
    synchronizeTabs: true,
  });
};

export { default as auth } from './auth';
export { default as fetch } from './fetch';
export { default as api } from './api';
export { default as users } from './users';
