/* eslint-disable react/prop-types */
import React, { Fragment, Children } from 'react';
import PropTypes from 'prop-types';

import { css } from '@emotion/core';

import styled from '@emotion/styled';

import Space from 'atoms/Space';

export const Fill = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
`;

const styles = ({
  isVertical,
  isFullWidth,
  padding,
  isVerticalCentered,
  isCentered,
  isGrown,
  isWrapped,
}) => css`
  width: ${isFullWidth ? '100%' : 'auto'};
  display: flex;
  flex-direction: ${isVertical ? 'column' : 'row'};

  ${isWrapped &&
    css`
      flex-wrap: wrap;
    `}

  align-items: ${isVerticalCentered ? 'center' : 'flex-start'};
  justify-content: ${isCentered ? 'center' : 'flex-start'};
  text-align: ${isCentered ? 'center' : 'left'};

  ${isVertical &&
    isCentered &&
    css`
      align-items: ${isCentered ? 'center' : 'flex-start'};
      justify-content: ${isVerticalCentered ? 'center' : 'flex-start'};
    `};

  ${isGrown &&
    css`
      flex-grow: 1;
    `}

  padding: ${(() => {
    let paddingToUse = padding;
    if (typeof padding === 'number') paddingToUse = padding + 'px';
    return paddingToUse;
  })()};    

`;

const Wrapper = styled('div')`
  ${styles}
`;

const Group = ({
  children,
  isVertical,
  isFullWidth,
  spaceSize,
  padding,
  isVerticalCentered,
  isCentered,
  isGrown,
  isWrapped,
  ...rest
}) => (
  <Wrapper
    isVertical={isVertical}
    isFullWidth={isFullWidth}
    spaceSize={spaceSize}
    padding={padding}
    isVerticalCentered={isVerticalCentered}
    isCentered={isCentered}
    isGrown={isGrown}
    isWrapped={isWrapped}
    {...rest}>
    {Children.toArray(children).map((element, index) => (
      <Fragment key={index}>
        {index !== 0 && <Space isVertical={isVertical} size={spaceSize} />}
        {element}
      </Fragment>
    ))}
  </Wrapper>
);

Group.defaultProps = {
  isCentered: null,
  isGrid: false,
  isVertical: false,
  isFullWidth: false,
  spaceSize: 2,
  padding: 0,
};

Group.propTypes = {
  isCentered: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isGrid: PropTypes.bool,
  isGrown: PropTypes.bool,
  isVertical: PropTypes.bool,
  isVerticalCentered: PropTypes.bool,
  isWrapped: PropTypes.bool,
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  spaceSize: PropTypes.number,
};

export default Group;
