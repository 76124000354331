import React from 'react';

import { navigate } from '@reach/router';

import colors from 'assets/themes/colors';

import { Group, GeneralText, Button } from 'atoms';

import { ImageWrapper, Space } from 'atoms';
import MeteorLogo from 'assets/media/images/meteor-logo-2@2x.png';

import { returnTo } from 'services/utilities';

const Home = () => {
  return (
    <Group
      isVertical
      isCentered
      style={{
        width: '100vw',
        padding: 20,
        textAlign: 'center',
        margin: 'auto',
        maxWidth: 415,
      }}>
      <Space size={60} isVertical />
      <ImageWrapper source={MeteorLogo} height={200} />
      <Space isLarge isVertical />

      <Button isFullWidth onClick={() => navigate(returnTo('/auth/signup'))}>
        JOIN
      </Button>
      <br />
      <Button
        isFullWidth
        isOutlined
        onClick={() => navigate(returnTo('/auth/signin'))}>
        LOGIN
      </Button>

      <Space isLarge isVertical />

      <Group isCentered isFullWidth>
        <Button isBare onClick={() => navigate('/')}>
          <GeneralText color={colors.white}>PLAY NOW!</GeneralText>
        </Button>
      </Group>
    </Group>
  );
};

export default Home;
