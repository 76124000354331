import { useSelector } from 'react-redux';

const useGameSteps = (keys, callback = () => {}) => {
  const { main, gameType, teamsSelection, gameplay } = useSelector(
    state => state
  );

  const { userData } = main;
  const { type, modality } = gameType;
  const { teams, competitors } = teamsSelection;
  const { entryFees, selectedEntryFee, predictions } = gameplay;

  let predictionsValidity = false;

  predictionsValidity = !!(
    predictions &&
    competitors &&
    Object.keys(predictions).filter(p => predictions[p] !== null).length ===
      competitors.length
  );

  const { promoCash, withdrawableFunds } = userData || {};

  const hasFundsFieldsToCalculate =
    selectedEntryFee &&
    promoCash !== undefined &&
    withdrawableFunds !== undefined;

  const hasFunds =
    hasFundsFieldsToCalculate &&
    promoCash + withdrawableFunds >= selectedEntryFee.entryFee;

  const steps = {
    gameType: !!type,
    modality: !!modality,
    hasFunds: modality === 'free' ? true : !!hasFunds,
    teams: !!teams,
    competitors: !!competitors,
    entryFeeInstance: !!selectedEntryFee,
    timeBlock: !!(teams && teams[0].timeBlock),
    entryFees: !!entryFees.filter(e => !e.isDisabled)[0],
    predictions: predictionsValidity,
  };

  const data = {
    gameType: type,
    modality,
    hasFunds: modality === 'free' ? true : !!hasFunds,
    teams,
    timeBlock: teams && teams[0].timeBlock,
    competitors,
    entryFeeInstance: selectedEntryFee,
    predictions,
  };

  let validator = null;

  if (typeof keys === 'string') {
    validator = steps[keys];
  }

  if (typeof keys === 'object') {
    if (keys.length) {
      validator = keys.filter(key => !steps[key]).length < 1;
    } else {
      validator = Object.keys(steps).filter(key => !steps[key]).length < 1;
    }
  }

  if (typeof callback === 'function') callback(validator, data);

  return validator !== null ? validator : steps;
};

export default useGameSteps;
