import React from 'react';
import PropTypes from 'prop-types';

import TeamSlider from 'molecules/TeamSlider';

const TeamsSelector = ({ listedTeams, onSelect, selected, exclude }) => {
  selected = selected || {};
  exclude = exclude || {};
  const normalizedData = [...listedTeams].map(item => {
    const isHome = item.homeTeam;
    const isHomeSelected = exclude.homeTeam;
    const teamToCompare = isHome ? item.homeTeamName : item.awayTeamName;
    if (!(exclude && exclude.teamName === teamToCompare)) {
      if (
        (isHomeSelected && exclude.awayTeamName !== item.awayTeamName) ||
        (!isHomeSelected && exclude.homeTeamName !== item.homeTeamName)
      ) {
        return {
          source: isHome ? item.homeTeamLogoUrl : item.awayTeamLogoUrl,
          isSelected: selected && item.teamName === selected.teamName,
          name: teamToCompare,
        };
      }
    }
  });
  return <TeamSlider teams={normalizedData} onSelect={onSelect} />;
};

TeamsSelector.defaultProps = {
  listedTeams: [],
};

TeamsSelector.propTypes = {
  listedTeams: PropTypes.array,
  exclude: PropTypes.object,
  onSelect: PropTypes.func,
  selected: PropTypes.object,
};

export default TeamsSelector;
