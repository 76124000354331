import { persistor } from 'core/store';
import { navigate } from '@reach/router';

import localforage from 'localforage';

import { auth } from 'services/firebase';

export const fetchUserData = uid => ({
  type: 'FETCH_USER_DATA',
  uid,
});

export const resetGame = () => ({
  type: 'RESET_GAME',
});

export const signOut = () => {
  const pathname = location.pathname.replace('/', '');
  persistor.purge().then(() => {
    auth()
      .signOut()
      .then(() => {
        localforage
          .clear()
          .then(() =>
            navigate(`/auth${pathname ? `?returnTo=${pathname}` : ''}`)
          );
      });
  });
  return { type: 'RESET' };
};
