import React from 'react';

import {
  Icon,
  Space,
  GeneralText,
  VerticalDivider,
  Button,
  Badge,
  Group,
} from 'atoms';
import { SignOut, LockAlt } from 'assets/icons';
import { Fill } from 'atoms/Group';
import { Pennant } from 'assets/icons';
import ImageWrapper from 'atoms/ImageWrapper';
import NFLLogo from 'assets/media/logos/nfl/NFL.svg';

import useUserData from 'hooks/useUserData';

import colors from 'assets/themes/colors';

import useEntryCancellation from './useEntryCancellation';

import moment from 'moment';
import { navigate } from '@reach/router';

import { timeDiff } from 'services/utilities';

const CurrentEntries = ({ hideDrawer }) => {
  const [session] = useUserData();
  const isAnonymous = session && session.isAnonymous;

  const entries = session.currentEntries || [];

  const [
    CancelEntry,
    { confirmCancellation, cancellationList },
  ] = useEntryCancellation([...entries].map(e => e.id));

  if (isAnonymous || !entries) return null;

  return (
    <Group isVertical isFullWidth isVerticalCentered>
      <Group isFullWidth isVerticalCentered spaceSize={8}>
        <Group isFullWidth isVerticalCentered>
          <Icon>
            <Pennant />
          </Icon>
          <GeneralText>
            <b>Active Entries</b>
          </GeneralText>
          <Fill />
          <GeneralText>
            <b>({entries.length})</b>
          </GeneralText>
        </Group>
      </Group>

      <Space isVertical size={10} />

      <Group isFullWidth isCentered isVertical spaceSize={16}>
        {entries.length === 0 && (
          <GeneralText color={colors.quaternary}>
            <b>You have no contests submitted yet</b>
          </GeneralText>
        )}

        {entries.map(entry => {
          const isFree = entry.entryFeeInstance.entryFee === 0;
          const maxEntries = entry.entryFeeInstance.maxEntries;
          const payoutType = entry.entryFeeInstance.payout == 0 ? 50 : 25;
          const totalPrice =
            ((entry.entryFeeInstance.entryFee * maxEntries) / 100) * 90;
          let payoutValue = totalPrice / ((maxEntries / 100) * payoutType);

          if (maxEntries < 1) payoutValue = 0;

          const isInCancellation = cancellationList[entry.id];

          let timeUntilStart = timeDiff(
            moment.unix(entry.timeBlock.start.seconds)
          );

          timeUntilStart = timeUntilStart * 60; // In minutes

          const canCancell = timeUntilStart > 90;

          return (
            <Group
              isFullWidth
              isCentered
              isVerticalCentered
              spaceSize={6}
              style={{ opacity: isInCancellation ? 0.4 : 1 }}
              key={entry.id}>
              <ImageWrapper source={NFLLogo} alt={'NFL Logo'} height={22} />
              <VerticalDivider height={6} />

              {isFree ? (
                <Badge isSmall>
                  <b>FREE</b>
                </Badge>
              ) : (
                <Badge isSecondary isSmall>
                  <b>PAID</b>
                </Badge>
              )}

              <VerticalDivider height={6} />
              <GeneralText isSmall color={colors.tertiary}>
                {entry.entryFeeInstance.gameType === 1 ? (
                  <b>Over/Under</b>
                ) : (
                  <b>Statpicks</b>
                )}
              </GeneralText>
              <Fill />
              {isFree ? (
                <GeneralText color={colors.tertiary}>
                  <b>TOP {payoutType}</b>
                </GeneralText>
              ) : (
                <>
                  <GeneralText color={colors.tertiary}>
                    Payout&nbsp;
                  </GeneralText>
                  <GeneralText isLarge color={colors.tertiary}>
                    <b>${payoutValue}</b>
                  </GeneralText>
                </>
              )}
              <Button
                isBare
                disabled={isInCancellation}
                onClick={() => confirmCancellation(entry.id)}>
                <Icon color={colors.mattedGray} size={17}>
                  <SignOut />
                  {/* {canCancell ? <SignOut /> : <LockAlt />} */}
                </Icon>
              </Button>
            </Group>
          );
        })}
      </Group>

      <Space isVertical size={15} />
      <Button
        isBare
        onClick={() => {
          navigate('/refunds');
          hideDrawer();
        }}>
        <GeneralText
          isSmall
          color={colors.accent}
          style={{ textAlign: 'center' }}>
          Please visit our Refund and Cancellation Policy
        </GeneralText>
      </Button>

      <CancelEntry />
    </Group>
  );
};

CurrentEntries.propTypes = {};

export default CurrentEntries;
