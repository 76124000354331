import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import * as actions from './actions';

import { MainContext } from 'containers/MainContainer';
import { navigate } from '@reach/router';

// Components
import Wrapper from './components/Wrapper';
import TeamNumberSelector from './components/TeamNumberSelector';
import TeamsSelector from './components/TeamsSelector';
import CurrentTeamCard from './components/CurrentTeamCard';
import OldTimeBlocks from './components/OldTimeBlocks';

import { Button, GeneralText } from 'atoms';

import useGameSteps from 'hooks/useGameSteps';
import { useDispatch } from 'react-redux';

import useGuidedTour from 'hooks/guidedTour';
import { teamSelectionTour } from 'hooks/guidedTour/tours';

export const TeamsSelection = props => {
  const {
    fetchAvailableTeams,
    updateSelectedTeams,
    availableTeams,
    dayDivisors,
    dayDivisor,
    teams,
    gameType,
  } = props;

  const dispatch = useDispatch();

  const { isOnline } = useContext(MainContext);

  const teamsByNumber = teams && { 1: teams[0], 2: teams[1] };
  const teamsList = availableTeams[dayDivisor || dayDivisors[0]];
  const [listedTeams, updateList] = useState(teamsList);
  const [selection, updateSelection] = useState(teamsByNumber || {});
  const [teamNumber, setTeamNumber] = useState(1);

  const [selectedDayDivisor, setDayDivisor] = useState(
    dayDivisor || dayDivisors[0]
  );
  const tourInteractions = [
    {
      stepIndex: 4,
      selector: '[data-guided-tour-emulate-click="team-logo"]',
      nextStepDelay: 2000,
    },
  ];
  const [Tour] = useGuidedTour({
    autoStart: true,
    isControlled: true,
    tourInteractions,
    tourName: teamSelectionTour.name,
  });

  useEffect(() => {
    if (isOnline) fetchAvailableTeams();
  }, []);

  const isSelectionDone = !!(selection[1] && selection[2]);

  useEffect(() => updateList(teamsList), [availableTeams, teamsList]);

  useEffect(() => {
    if (isSelectionDone) updateSelectedTeams([selection[1], selection[2]]);
  }, [selection]);

  useEffect(() => {
    if (isSelectionDone && teams === null) {
      updateSelection({});
      setTeamNumber(1);
    }
  }, [teams]);

  if (!useGameSteps(['gameType'])) {
    navigate('/gametype');
    return null;
  }

  return (
    <Wrapper
      dayDivisors={dayDivisors}
      dayDivisor={selectedDayDivisor}
      onChangeDayDivisor={({ target }) => {
        updateSelection({});
        setTeamNumber(1);
        setDayDivisor(target.value);
        updateList(availableTeams[target.value]);
      }}>
      {/* TODO: Hide this in production!!!! */}
      {/* {process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'demo' && (
        <OldTimeBlocks onChange={fetchAvailableTeams} />
      )} */}

      {listedTeams ? (
        <TeamNumberSelector
          current={teamNumber}
          isTeamOneSelected={selection[1]}
          isTeamTwoSelected={selection[2]}
          onChange={setTeamNumber}
        />
      ) : (
        <GeneralText
          style={{
            width: '100%',
            textAlign: 'center',
            padding: '30px 30px 10px',
          }}>
          Sorry, no available competitions lineups
        </GeneralText>
      )}

      <TeamsSelector
        listedTeams={listedTeams}
        selected={selection[teamNumber]}
        exclude={selection[teamNumber === 1 ? 2 : 1]}
        onSelect={({ name }) => {
          dispatch({ type: 'RESET_PREDICTIONS' });
          updateSelection({
            ...selection,
            [teamNumber]: listedTeams.filter(t => t.teamName === name)[0],
          });
        }}
      />

      <CurrentTeamCard
        currentTeam={selection[teamNumber]}
        teamNumber={teamNumber}
        gameType={gameType}
      />

      <div style={{ width: '100%', padding: '5px 15px 20px' }}>
        <Button
          data-guided-tour="lets-play"
          isFullWidth
          style={{ margin: 'auto', maxWidth: 415 }}
          disabled={!isSelectionDone}
          onClick={() => navigate('/gameplay')}>
          LET&#39;S PLAY!
        </Button>
      </div>
      <Tour
        steps={teamSelectionTour.steps}
        continuous
        showSkipButton
        disableScrolling
        spotlightClicks
      />
    </Wrapper>
  );
};

TeamsSelection.defaultProps = {
  availableTeams: {},
};

TeamsSelection.propTypes = {
  availableTeams: PropTypes.object,
  dayDivisor: PropTypes.string,
  dayDivisors: PropTypes.array,
  fetchAvailableTeams: PropTypes.func,
  gameType: PropTypes.string,
  selectedTeams: PropTypes.array,
  teams: PropTypes.array,
  updateSelectedTeams: PropTypes.func,
};

export default connect(
  store => ({
    ...store.teamsSelection,
    gameType: store.gameType.type,
  }),
  { ...actions }
)(TeamsSelection);
