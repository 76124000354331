import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import DayDivisor from 'molecules/DayDivisor';
import { Button, Icon, Group, ImageWrapper } from 'atoms';
import { ArrowLeft } from 'assets/icons';

import NFLLogo from 'assets/media/logos/nfl/NFL.svg';

import { useAppContainer } from 'organisms/AppContainer';
import useAnalytics from 'hooks/useAnalytics';

const Wrapper = ({ children, dayDivisors, dayDivisor, onChangeDayDivisor }) => {
  const { logEvent } = useAnalytics();

  const { reload } = useAppContainer({
    header: {
      renderLeft: (
        <Group isVerticalCentered spaceSize={6}>
          <Button isBare onClick={() => window.history.back()}>
            <Icon size={16} color="#FFF">
              <ArrowLeft />
            </Icon>
          </Button>
          <Button isBare onClick={() => window.history.back()}>
            <ImageWrapper source={NFLLogo} alt={'NFL Logo'} height={42} />
          </Button>
        </Group>
      ),
      renderCenter: (
        <DayDivisor
          defaultDayDivisor={dayDivisor || dayDivisors[0]}
          divisors={dayDivisors}
          onSelect={onChangeDayDivisor}
        />
      ),
    },
  });

  useEffect(() => {
    if (dayDivisor) {
      logEvent('day_divisor', { item_name: dayDivisor });
    }
  }, [dayDivisor]);

  useEffect(() => {
    reload();
  }, [dayDivisors]);

  return children;
};

Wrapper.propTypes = {
  children: PropTypes.any,
  dayDivisor: PropTypes.string,
  dayDivisors: PropTypes.array,
  onChangeDayDivisor: PropTypes.func,
};

export default Wrapper;
