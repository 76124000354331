import React from 'react';
import PropTypes from 'prop-types';

import { Button, Icon, Group, ImageWrapper } from 'atoms';
import { ArrowLeft } from 'assets/icons';

import NFLLogo from 'assets/media/logos/nfl/NFL.svg';

import { useAppContainer } from 'organisms/AppContainer';

const Wrapper = ({ children }) => {
  useAppContainer({
    header: {
      renderLeft: (
        <Group isVerticalCentered spaceSize={6}>
          <Button isBare onClick={() => window.history.back()}>
            <Icon size={16} color="#FFF">
              <ArrowLeft />
            </Icon>
          </Button>
          <Button isBare onClick={() => window.history.back()}>
            <ImageWrapper source={NFLLogo} alt={'NFL Logo'} height={42} />
          </Button>
        </Group>
      ),
      renderCenter: 'Final Step',
    },
  });

  return <div style={{ height: 'calc(100vh - 90px)' }}>{children}</div>;
};

Wrapper.propTypes = {
  children: PropTypes.any,
};

export default Wrapper;
