import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/core';

import colors from 'assets/themes/colors';

const blink = keyframes`
  0% {
    background-color: ${colors.white};
  }
  50% {
    background-color: ${colors.positive};
  }
  100% {
    background-color: ${colors.white};
  }
`;

const blinkReset = keyframes`
  0% {
    background-color: ${colors.primary};
  }
  100% {
    background-color: ${colors.white};
  }
`;

const Wrapper = styled.label`
  cursor: pointer;
  position: relative;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  border: 1px solid;
  border-color: ${({ isChecked }) =>
    isChecked ? colors.positive : colors.tertiary};
  background-color: transparent;
  transition: all 0.2s ease-out;
  width: 64px;
  height: 64px;
  border-radius: 6px;
  box-shadow: 0px 0px 2px
    ${({ isChecked }) => (isChecked ? colors.positive : colors.tertiary)};
  ${({ isChecked }) => {
    if (isChecked == true)
      return css`
        animation: ${blink} 0.2s ease-out;
      `;
    if (isChecked == false)
      return css`
        animation: ${blinkReset} 0.2s ease-out;
      `;
  }}
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: -1;
`;

const ToggleContainer = ({ id, name, onChange, render, type, checked }) => {
  const [isChecked, setChecked] = useState(checked);

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  const inputRef = useRef(null);

  const handleChanges = ({ target }) => {
    setChecked(target.checked);
    onChange({ name, checked: target.checked });
  };

  return (
    <Wrapper isChecked={isChecked} htmlFor={id || name}>
      {render({ isChecked, inputRef })}
      <Input
        checked={isChecked}
        id={id || name}
        name={name}
        onChange={handleChanges}
        ref={inputRef}
        type={type}
      />
    </Wrapper>
  );
};

ToggleContainer.defaultProps = {
  id: null,
  name: 'ToggleContainer',
  onChange: () => {},
  type: 'checkbox',
};

ToggleContainer.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  render: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default ToggleContainer;
