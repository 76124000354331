import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import localforage from 'localforage';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

const useGuidedTour = config => {
  /*
   * config includes autoStart, delay, tourInteraction (see defaultConfig for info on autoStart and delay)
   * TourInteractions: an array of objects to be clicked as part of the tour
   * TourInteractions.stepIndex (type: number) -- the step where interaction should run
   * TourInteractions.selector (type: string) -- selector for element to be actioned
   * TourInteractions.nextStepDelay (type: number) -- delay to run next step after action
   * TourInteractions.isControlled (type: boolean) -- sets mode to be controlled (used if you plan to use interactions)
   * TourInteractions example: { stepIndex: 4, selector: '[data-guided-tour-emulate-click="team-logo"]', nextStepDelay: 2000 },
   */

  const defaultConfig = {
    autoStart: false,
    delay: 2000,
    isControlled: false,
    tourInteractions: [],
  };
  const tourConfig = { ...defaultConfig, ...(config || {}) };
  const {
    autoStart,
    delay,
    isControlled,
    tourInteractions,
    tourName,
  } = tourConfig;

  const [isRunning, toggleIsRunning] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, toggleIsLoading] = useState(true);
  const [step, setStepIndex] = useState(0);

  const timeout = setTimeout;

  useEffect(() => {
    //TODO: refactor to not rely on catch
    localforage
      .getItem('guidedTours')
      .then(tour => {
        if (tour[tourName].isComplete) {
          toggleIsRunning(false);
        }
      })
      .catch(() => {
        //will run if no value returned for guidedTours get
        if (autoStart) {
          timeout(() => {
            toggleIsRunning(true);
            toggleIsLoading(false);
          }, delay);
        }
      });
    return () => {
      toggleIsRunning(false);
      clearTimeout(timeout);
    };
  }, []);

  const cacheTourDataToDevice = name => {
    const tourData = { [name]: { isComplete: true } };
    localforage
      .getItem('guidedTours')
      .then(storedTours => {
        localforage.setItem('guidedTours', {
          ...tourData,
          ...storedTours,
        });
      })
      .catch(() => {
        localforage.setItem('guidedTours', tourData);
      });
  };

  //TODO refactor this nightmare
  const handleTour = data => {
    // eslint-disable-next-line no-unused-vars
    const { action, index, lifecycle, size, status, type } = data;

    //TODO: status never toggles to finished in isControlled mode, which is preferred way of checking for
    // end of tour. Figure out why and update. 2nd condition in OR is workaround
    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      (index === size - 1 && type === 'step:after' && isRunning)
    ) {
      // Need to set our running state to false, so we can restart if we click start again.
      toggleIsRunning(false);
      cacheTourDataToDevice(tourName);
    }

    if (isControlled) {
      //Joyride handles this automatically when uncontrolled and will break uncontrolled tours
      if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
        // Update state to advance the tour
        setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
      }
    }

    if (tourInteractions.length > 0 && isControlled) {
      //some tours have interactions so we filter if we are on desired step to run the interaction
      tourInteractions
        .filter(interaction => interaction.stepIndex === index)
        .forEach(result => {
          const { selector, nextStepDelay } = result;

          if (lifecycle === 'complete') {
            toggleIsRunning(false);
            const elemToAction = document.querySelector(selector);
            elemToAction.click();
            timeout(() => {
              toggleIsRunning(true);
              setStepIndex(5);
              // toggleIsRunning(true);
            }, nextStepDelay);
          }
        });
    }

    // console.group(type);
    // console.log(data); //eslint-disable-line no-console
    // console.groupEnd();
  };

  const GuidedTour = ({ steps, callback, ...rest }) => {
    // let props = { steps, callback, ...rest };

    // if (isControlled) {
    //   // console.log(`we are in controlled mode!`);
    //   props = { ...props, stepIndex: step };
    //   // console.log(props);
    // }

    // return <Joyride {...props} />;

    if (isControlled) {
      return (
        <Joyride
          stepIndex={step}
          callback={callback}
          run={isRunning}
          steps={steps}
          {...rest}
        />
      );
    } else if (!isControlled) {
      return (
        <Joyride callback={callback} run={isRunning} steps={steps} {...rest} />
      );
    } else {
      return null;
    }
  };

  GuidedTour.defaultProps = {
    callback: handleTour,
  };

  GuidedTour.propTypes = {
    steps: PropTypes.array.isRequired,
    callback: PropTypes.func,
  };

  return [GuidedTour];
};

useGuidedTour.propTypes = {
  config: PropTypes.shape({
    tourName: PropTypes.string.isRequired,
    autoStart: PropTypes.bool,
    delay: PropTypes.number,
  }),
};

export default useGuidedTour;
