import React from 'react';
import PropTypes from 'prop-types';

import GeneralText from 'atoms/GeneralText';

const StatpicksPrediction = ({ selected, stats }) => {
  return (
    <GeneralText isSmall>
      {selected !== null && selected !== undefined ? (
        <b>{selected}</b>
      ) : (
        <b>{stats.value}</b>
      )}
    </GeneralText>
  );
};

StatpicksPrediction.propTypes = {
  selected: PropTypes.any,
  stats: PropTypes.object,
};

export default StatpicksPrediction;
