import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import colors from 'assets/themes/colors';
import { GeneralText, Group, Space, List } from 'atoms';
import { Fill } from 'atoms/Group';
import CardWrapper from 'molecules/CardWrapper';

/* eslint-disable-next-line */
const BasicTextCard = ({ title, data, additionalData }) => {
  return (
    <CardWrapper
      topLabel={
        <GeneralText isLarge color={colors.white}>
          {title}
        </GeneralText>
      }>
      <Group padding={20} isVertical>
        {additionalData && (
          <>
            <Group isFullWidth>
              {/* eslint-disable-next-line */
              additionalData.map((item, index) => (
                <Fragment key={index}>
                  {index > 0 && <Fill />}
                  <Group spaceSize={5}>
                    <GeneralText>
                      <b>{item.label}</b>
                    </GeneralText>
                    <GeneralText>{item.value}</GeneralText>
                  </Group>
                </Fragment>
              ))}
            </Group>
            <Space isMedium isVertical />
          </>
        )}
        <List>
          {data /* eslint-disable-next-line */ &&
            data.map((item, index) => (
              <Group key={index}>
                <GeneralText>
                  <b>{item.label}</b>
                </GeneralText>
                <Fill />
                <GeneralText>{item.value}</GeneralText>
              </Group>
            ))}
        </List>
      </Group>
    </CardWrapper>
  );
};

BasicTextCard.propTypes = {
  title: PropTypes.node.isRequired,
  additionalData: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  data: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};

export default BasicTextCard;
