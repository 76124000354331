import { css } from '@emotion/core';

export const GLOBAL_FONT_SIZE = 16;

export const px2Rem = pxValues =>
  pxValues.map(value => `${value / GLOBAL_FONT_SIZE}rem`);

export default css`
  /* nunito-sans-200 - latin */
  @font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 200;
    src: url('./fonts/nunito-sans/nunito-sans-v5-latin-200.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans ExtraLight'), local('NunitoSans-ExtraLight'),
      url('./fonts/nunito-sans/nunito-sans-v5-latin-200.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('./fonts/nunito-sans/nunito-sans-v5-latin-200.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('./fonts/nunito-sans/nunito-sans-v5-latin-200.woff') format('woff'),
      /* Modern Browsers */
        url('./fonts/nunito-sans/nunito-sans-v5-latin-200.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('./fonts/nunito-sans/nunito-sans-v5-latin-200.svg#NunitoSans')
        format('svg'); /* Legacy iOS */
  }
  /* nunito-sans-300italic - latin */
  @font-face {
    font-family: 'Nunito Sans';
    font-style: italic;
    font-weight: 300;
    src: url('./fonts/nunito-sans/nunito-sans-v5-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans Light Italic'), local('NunitoSans-LightItalic'),
      url('./fonts/nunito-sans/nunito-sans-v5-latin-300italic.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */
        url('./fonts/nunito-sans/nunito-sans-v5-latin-300italic.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('./fonts/nunito-sans/nunito-sans-v5-latin-300italic.woff')
        format('woff'),
      /* Modern Browsers */
        url('./fonts/nunito-sans/nunito-sans-v5-latin-300italic.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('./fonts/nunito-sans/nunito-sans-v5-latin-300italic.svg#NunitoSans')
        format('svg'); /* Legacy iOS */
  }
  /* nunito-sans-regular - latin */
  @font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/nunito-sans/nunito-sans-v5-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans Regular'), local('NunitoSans-Regular'),
      url('./fonts/nunito-sans/nunito-sans-v5-latin-regular.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */
        url('./fonts/nunito-sans/nunito-sans-v5-latin-regular.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('./fonts/nunito-sans/nunito-sans-v5-latin-regular.woff')
        format('woff'),
      /* Modern Browsers */
        url('./fonts/nunito-sans/nunito-sans-v5-latin-regular.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('./fonts/nunito-sans/nunito-sans-v5-latin-regular.svg#NunitoSans')
        format('svg'); /* Legacy iOS */
  }
  /* nunito-sans-600 - latin */
  @font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    src: url('./fonts/nunito-sans/nunito-sans-v5-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans SemiBold'), local('NunitoSans-SemiBold'),
      url('./fonts/nunito-sans/nunito-sans-v5-latin-600.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('./fonts/nunito-sans/nunito-sans-v5-latin-600.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('./fonts/nunito-sans/nunito-sans-v5-latin-600.woff') format('woff'),
      /* Modern Browsers */
        url('./fonts/nunito-sans/nunito-sans-v5-latin-600.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('./fonts/nunito-sans/nunito-sans-v5-latin-600.svg#NunitoSans')
        format('svg'); /* Legacy iOS */
  }
  /* nunito-sans-700 - latin */
  @font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/nunito-sans/nunito-sans-v5-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans Bold'), local('NunitoSans-Bold'),
      url('./fonts/nunito-sans/nunito-sans-v5-latin-700.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('./fonts/nunito-sans/nunito-sans-v5-latin-700.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('./fonts/nunito-sans/nunito-sans-v5-latin-700.woff') format('woff'),
      /* Modern Browsers */
        url('./fonts/nunito-sans/nunito-sans-v5-latin-700.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('./fonts/nunito-sans/nunito-sans-v5-latin-700.svg#NunitoSans')
        format('svg'); /* Legacy iOS */
  }
  /* nunito-sans-800 - latin */
  @font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 800;
    src: url('./fonts/nunito-sans/nunito-sans-v5-latin-800.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans ExtraBold'), local('NunitoSans-ExtraBold'),
      url('./fonts/nunito-sans/nunito-sans-v5-latin-800.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('./fonts/nunito-sans/nunito-sans-v5-latin-800.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('./fonts/nunito-sans/nunito-sans-v5-latin-800.woff') format('woff'),
      /* Modern Browsers */
        url('./fonts/nunito-sans/nunito-sans-v5-latin-800.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('./fonts/nunito-sans/nunito-sans-v5-latin-800.svg#NunitoSans')
        format('svg'); /* Legacy iOS */
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: ${GLOBAL_FONT_SIZE}px;
    font-family: 'Nunito Sans', sans-serif;
    margin: 0;
  }

  * {
    box-sizing: border-box;
  }
`;
