import request from 'axios';

import { auth } from 'services/firebase';

export default {
  createEntry: (body, token) =>
    new Promise((resolve, reject) => {
      request
        .post(`${process.env.firebaseCloudFunctionsURL}/createEntry`, body, {
          headers: { authorization: 'Bearer ' + token },
        })
        .then(({ data }) => resolve({ ...data }))
        .catch(error => reject(error.response.data));
    }),
  cancelEntry: (body, token) =>
    new Promise((resolve, reject) => {
      request
        .post(`${process.env.firebaseCloudFunctionsURL}/cancelEntry`, body, {
          headers: { authorization: 'Bearer ' + token },
        })
        .then(({ data }) => resolve({ ...data }))
        .catch(error => reject(error.response.data));
    }),
  setUser: async body => {
    const token = await auth().currentUser.getIdToken();
    return new Promise((resolve, reject) => {
      request
        .post(`${process.env.firebaseCloudFunctionsURL}/upsertUser`, body, {
          headers: { authorization: 'Bearer ' + token },
        })
        .then(({ data }) => resolve({ ...data }))
        .catch(error => reject(error.response.data));
    });
  },
  verifyAccount: (body, token, callback) =>
    new Promise((resolve, reject) => {
      request
        .post(`${process.env.firebaseCloudFunctionsURL}/verifyAccount`, body, {
          headers: { authorization: 'Bearer ' + token },
        })
        .then(callback || resolve)
        .catch(error => reject(error.response.data));
    }),
  saveCreditCard: (body, token, callback) =>
    new Promise((resolve, reject) => {
      request
        .post(`${process.env.firebaseCloudFunctionsURL}/saveCreditCard`, body, {
          headers: { authorization: 'Bearer ' + token },
        })
        .then(callback || resolve)
        .catch(error => reject(error.response.data));
    }),

  getVaultData: token =>
    new Promise((resolve, reject) => {
      request
        .get(
          `http://localhost:5001/meteor-fantasy-staging/us-central1/getVaultData`,
          {
            // TODO: We gonna finished that after launch
            // .post(`${process.env.firebaseCloudFunctionsURL}/getVaultData`, body, {
            headers: { authorization: 'Bearer ' + token },
          }
        )
        .then(({ data }) => resolve({ ...data }))
        .catch(error => reject(error.response));
    }),

  addFunds: (body, token) =>
    new Promise((resolve, reject) => {
      request
        .post(
          `http://localhost:5001/meteor-fantasy-staging/us-central1/addFunds`,
          body,
          {
            // TODO: We gonna finished that after launch
            // .post(`${process.env.firebaseCloudFunctionsURL}/addFunds`, body, {
            headers: { authorization: 'Bearer ' + token },
          }
        )
        .then(({ data }) => resolve({ ...data }))
        .catch(error => reject(error.response));
    }),

  withdraw: (body, token, callback) =>
    new Promise((resolve, reject) => {
      request
        .post(`${process.env.firebaseCloudFunctionsURL}/withdraw`, body, {
          headers: { authorization: 'Bearer ' + token },
        })
        .then(callback || resolve)
        .catch(error => reject(error.response));
    }),
  sendEmail: (body, callback) =>
    new Promise((resolve, reject) => {
      request
        .post(
          `${process.env.firebaseCloudFunctionsURL}/createSupportEmail`,
          body
        )
        .then(callback || resolve)
        .catch(error => reject(error.response.data));
    }),
};
