import React from 'react';
import PropTypes from 'prop-types';

import { NumEntriesVsMaxEntries, EntryFees, Payout } from 'molecules';
import { Group, GeneralText } from 'atoms';

const GameInstance = ({
  selectedValue,
  activeParticipants,
  totalParticipants,
  payout,
  payoutType,
  entryFees,
  onEntryFeeSelect,
}) => (
  <Group isVertical isFullWidth>
    <Group isFullWidth isVerticalCentered>
      <NumEntriesVsMaxEntries
        activeParticipants={activeParticipants}
        totalParticipants={totalParticipants}
      />
      <EntryFees
        entryFees={entryFees}
        defaultEntryFeeAmount={selectedValue}
        onEntryFeeSelect={onEntryFeeSelect}
      />
      {payout == 0 ? (
        <Group
          data-guided-tour="payout-free"
          isCentered
          isVerticalCentered
          isGrown>
          <Group isVertical isCentered>
            <GeneralText isMedium>
              <strong>{payoutType == 50 ? 'TOP 50' : 'TOP 25'}</strong>
            </GeneralText>
            {/* TODO: Refactor after MVP launch to make dynamic */}
            <GeneralText color="black">Payout: M$0.25</GeneralText>
          </Group>
        </Group>
      ) : (
        <Payout payout={payout} />
      )}
    </Group>
  </Group>
);

GameInstance.defaultProps = {
  selectedValue: 0,
  activeParticipants: 0,
  totalParticipants: 0,
  payout: 0,
  entryFees: [],
  onEntryFeeSelect: () => {},
};

GameInstance.propTypes = {
  activeParticipants: PropTypes.number.isRequired,
  beginnersOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  entryFee: PropTypes.number.isRequired,
  entryFees: PropTypes.arrayOf(
    PropTypes.shape({
      entryFee: PropTypes.number.isRequired,
      disabled: PropTypes.bool,
      beginnersOnly: PropTypes.bool,
      full: PropTypes.bool,
      gameType: PropTypes.number,
      id: PropTypes.string,
      maxEntries: PropTypes.number,
      payout: PropTypes.number,
      timeBlockId: PropTypes.string,
    })
  ).isRequired,
  full: PropTypes.bool,
  gameType: PropTypes.number,
  id: PropTypes.string,
  maxEntries: PropTypes.number,
  onEntryFeeSelect: PropTypes.func.isRequired,
  payout: PropTypes.number.isRequired,
  payoutType: PropTypes.any,
  selectedValue: PropTypes.number,
  timeBlockId: PropTypes.string,
  totalParticipants: PropTypes.number.isRequired,
};

export default GameInstance;
