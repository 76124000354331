import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import { Group, Button, GeneralText } from 'atoms';
import { Fill } from 'atoms/Group';

import colors from 'assets/themes/colors';

const Container = styled(Group)`
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px 12px 7px;
  background-color: ${colors.alert};
`;

const Flag = ({ onClickToResend, onClickToVerify }) => (
  <Container isFullWidth isVerticalCentered spaceSize={15}>
    <GeneralText isSmall color={colors.white}>
      <b>Please verify your email!</b>
    </GeneralText>
    <Fill />
    <Button isBare onClick={onClickToResend}>
      <GeneralText isSmall color={colors.white}>
        RESEND
      </GeneralText>
    </Button>
    <Button isBare onClick={onClickToVerify}>
      <GeneralText isSmall color={colors.white}>
        VERIFY
      </GeneralText>
    </Button>
  </Container>
);

Flag.propTypes = {
  onClickToResend: PropTypes.func,
  onClickToVerify: PropTypes.func,
};

export default Flag;
