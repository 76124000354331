import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Group, Space } from 'atoms';
import { Fill } from 'atoms/Group';

import callAddFunds from 'hooks/callAddFunds';

import UserInfo from './components/UserInfo';
import CurrentContest from './components/CurrentContest';
import Footer from './components/Footer';
import CurrentEntries from './components/CurrentEntries';

import colors from 'assets/themes/colors';

const SideDrawer = ({ hideDrawer }) => {
  const [AddFunds, toggleAddFunds] = callAddFunds();

  return (
    <Group isVertical style={{ height: '100%', padding: '20px 15px' }}>
      <UserInfo hideDrawer={hideDrawer} onClickToAddFunds={toggleAddFunds} />

      <div
        style={{
          width: '100%',
          height: 1,
          margin: '10px 0',
          flexShrink: 0,
          backgroundColor: colors.tertiary,
        }}
      />

      <CurrentContest hideDrawer={hideDrawer} />

      <Space isVertical size={20} />

      <CurrentEntries hideDrawer={hideDrawer} />

      <Fill />

      <Space isVertical size={30} />

      <Footer />

      <AddFunds />
    </Group>
  );
};

SideDrawer.propTypes = {
  hideDrawer: PropTypes.func,
};

export default memo(SideDrawer);
