import React from 'react';
import PropTypes from 'prop-types';

import { useTransition, animated } from 'react-spring';

import styled from '@emotion/styled';

const ListItem = styled(animated.li)`
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
  text-align: left;
  + li {
    margin-top: 15px;
  }
`;

const Container = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
`;

const List = ({ children, hasAnimation }) => {
  const transitions = useTransition(
    React.Children.toArray(children),
    item => item.key,
    {
      trail: hasAnimation ? 200 : 0,
      config: { duration: hasAnimation ? undefined : 0 },
      from: { transform: 'translate3d(0, -40px, 0)', opacity: 0 },
      enter: { transform: 'translate3d(0, 0px, 0)', opacity: 1 },
      leave: { transform: 'translate3d(0, -40px, 0)', opacity: 0 },
    }
  );

  return (
    <Container>
      {transitions.map(({ item, props, key }) => (
        <ListItem key={key} style={props}>
          {item}
        </ListItem>
      ))}
    </Container>
  );
};

List.defaultProps = {
  hasAnimation: true,
};

List.propTypes = {
  children: PropTypes.any.isRequired,
  hasAnimation: PropTypes.bool,
};

export default List;
