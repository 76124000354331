import React, { useState, useEffect, useContext } from 'react';

import { navigate } from '@reach/router';

import colors from 'assets/themes/colors';

import {
  Button,
  FormContainer,
  GeneralText,
  Group,
  Icon,
  Input,
  Title,
  ImageWrapper,
  Space,
  Checkbox,
} from 'atoms';
import { ModalHeader } from 'organisms';

import { Fill } from 'atoms/Group';
import Alert from 'molecules/Alert';
import useModal from 'hooks/useModal';
import MeteorLogo from 'assets/media/images/meteor-logo-2@2x.png';
import { Envelope, LockAlt, UserAlt } from 'assets/icons';

import { Terms as TermsPage } from 'pages';

import { auth, api } from 'services/firebase';

import { SessionContext } from 'containers/SessionManager';

import { returnTo } from 'services/utilities';

import useAnalytics from 'hooks/useAnalytics';

import { useDispatch } from 'react-redux';

const SignUp = () => {
  const [feedback, setFeedback] = useState('');
  const [Terms, toggleTerms] = useModal();
  const [isChecked, setIsChecked] = useState(false);
  const { reloadSession } = useContext(SessionContext);

  const { logEvent } = useAnalytics();

  const timeout = setTimeout;

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Group
      isVertical
      isFullWidth
      isCentered
      isVerticalCentered
      style={{ margin: 'auto', maxWidth: 415 }}>
      <Group isVertical isFullWidth isCentered spaceSize={20} padding={30}>
        <Space isLarge isVertical />
        <ImageWrapper source={MeteorLogo} height={180} />
        <Title color={colors.white}>Create Account</Title>

        <FormContainer
          onSubmit={async ({ email, password, firstName, lastName }) => {
            const credential = await auth.EmailAuthProvider.credential(
              email,
              password
            );
            auth()
              .currentUser.linkAndRetrieveDataWithCredential(credential)
              .then(
                async ({ user }) => {
                  logEvent('join_group', { group_id: 2 });
                  logEvent('signup');
                  await user.sendEmailVerification();
                  const newUser = await api.setUser({
                    firstName,
                    lastName,
                    email,
                  });
                  reloadSession().then(() => {
                    logEvent('login');
                    dispatch({
                      type: 'FETCH_USER_DATA_SUCCESS',
                      payload: newUser.data,
                    });
                    navigate(`/${returnTo() || 'gametype'}`);
                    // TODO: Uncomment after launch
                    // navigate(returnTo('/account-verification/new'))
                  });
                },
                ({ message }) => setFeedback(message)
              );
          }}
          render={({ values, handleChange, handleSubmit }) => {
            return (
              <Group isFullWidth isVertical spaceSize={20} isCentered>
                <Input
                  leftIcon={
                    <Icon
                      color={colors.accent}
                      size={18}
                      style={{ zIndex: 10 }}>
                      <UserAlt />
                    </Icon>
                  }
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  placeholder="First name"
                />
                <Input
                  leftIcon={
                    <Icon
                      color={colors.accent}
                      size={18}
                      style={{ zIndex: 10 }}>
                      <UserAlt />
                    </Icon>
                  }
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  placeholder="Last name"
                />
                <Input
                  leftIcon={
                    <Icon
                      color={colors.accent}
                      size={18}
                      style={{ zIndex: 10 }}>
                      <Envelope />
                    </Icon>
                  }
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  placeholder="Email"
                />
                <Input
                  leftIcon={
                    <Icon
                      color={colors.accent}
                      size={18}
                      style={{ zIndex: 10 }}>
                      <LockAlt />
                    </Icon>
                  }
                  name="password"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  placeholder="Password"
                />

                <Group spaceSize={5}>
                  <Checkbox
                    onChange={({ target }) => setIsChecked(target.checked)}
                    checked={isChecked}
                  />
                  {/* <CheckBox
                    isBare
                    aria-label={`T&C checkbox is ${
                      isChecked ? 'checked' : 'not checked'
                    }`}
                    onClick={() => setIsChecked(!isChecked)}>
                    <Icon
                      size={13}
                      color={isChecked ? colors.primary : colors.white}>
                      <Check />
                    </Icon>
                  </CheckBox> */}
                  <GeneralText
                    onClick={() => setIsChecked(!isChecked)}
                    color={colors.white}>
                    Yes, I agree to
                  </GeneralText>
                  <GeneralText onClick={toggleTerms} color={colors.accent}>
                    Terms and Conditions
                  </GeneralText>
                </Group>

                <Button
                  type="submit"
                  isFullWidth
                  disabled={!isChecked}
                  onClick={handleSubmit}>
                  CREATE ACCOUNT
                </Button>
              </Group>
            );
          }}
        />

        <Group isCentered isFullWidth>
          <Button isBare onClick={() => navigate('/auth/signin')}>
            <GeneralText color={colors.white}>
              Already have account?
            </GeneralText>
          </Button>
        </Group>
      </Group>

      <Fill />

      <Terms
        onClickToClose={toggleTerms}
        customHeader={() => (
          <ModalHeader
            onClickToClose={toggleTerms}
            title="Terms & Conditions"
          />
        )}>
        <TermsPage />
      </Terms>

      <Alert
        isOpen={!!feedback}
        disableClose
        title="Something happened"
        cancelButtonLabel="Sign in"
        proceedButtonLabel={'Try again'}
        onClickToCancel={() => navigate('/auth/signin')}
        onClickToProceed={() => setFeedback('')}>
        <GeneralText>{feedback}</GeneralText>
      </Alert>
    </Group>
  );
};

SignUp.propTypes = {};

export default SignUp;
