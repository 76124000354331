import React, { useContext } from 'react';

import { SessionContext } from 'containers/SessionManager';

import Wrapper from './Wrapper';

import colors from 'assets/themes/colors';
import { Group, GeneralText, Icon, Title } from 'atoms';
import ContainerWithBackground from 'molecules/ContainerWithBackground';

import { UserCircle } from 'assets/icons';

import YouEntered1 from 'assets/media/images/you-entered-1.png';

const UnverifiedEmail = () => {
  const { session } = useContext(SessionContext);

  return (
    <Wrapper>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <ContainerWithBackground
          source={YouEntered1}
          style={{
            flex: 1,
            height: 'auto',
          }}>
          <Group
            isVertical
            isCentered
            isVerticalCentered
            padding={20}
            spaceSize={60}
            style={{
              flex: 1,
              height: '100%',
              width: '100%',
              maxWidth: 450,
              margin: 'auto',
            }}>
            <Title color={colors.white} isLarge>
              Email not verified!
            </Title>
            <div style={{ height: 100 }}>
              <Icon color={colors.white} size={110}>
                <UserCircle />
              </Icon>
            </div>
            <GeneralText color={colors.white} isLarge>
              <b>
                Verify your email to proceed! An email was sent to{' '}
                {session.email}. Check your inbox then click on the link to
                verify your email.
              </b>
            </GeneralText>
          </Group>
        </ContainerWithBackground>
      </div>
    </Wrapper>
  );
};

export default UnverifiedEmail;
