import React, { useEffect, useState } from 'react';

import useModal from 'hooks/useModal';

import ContentVerify from './components/ContentVerify';
import ContentResend from './components/ContentResend';

import useUserData from 'hooks/useUserData';

const callEmailVerification = () => {
  // eslint-disable-next-line no-unused-vars
  const [session, reload] = useUserData();
  const [Modal, openModal] = useModal();

  const [operation, currentOperation] = useState(null);

  useEffect(() => () => currentOperation(null), []);

  const resend = () => {
    openModal();
    currentOperation('resend');
  };

  const verify = () => {
    reload().then(() => {
      openModal();
      currentOperation('verify');
    });
  };

  const close = () => currentOperation(null);

  const Render = () => {
    return (
      operation && (
        <>
          <Modal customHeader={() => null} isCovering={false}>
            {operation === 'resend' && <ContentResend onClose={close} />}
            {operation === 'verify' && <ContentVerify onClose={close} />}
          </Modal>
        </>
      )
    );
  };

  return [Render, verify, resend];
};

export default callEmailVerification;
