import React from 'react';
import PropTypes from 'prop-types';

import { Button, Group } from 'atoms';

const TeamsSelector = ({
  current,
  isTeamOneSelected,
  isTeamTwoSelected,
  onChange,
}) => {
  return (
    <Group isFullWidth padding={'10px 15px 5px'}>
      <Button
        data-guided-tour="team-select-1"
        isFullWidth
        isXSmall
        onClick={() => onChange(1)}
        isSecondary={current === 2}>
        <Group isVerticalCentered spaceSize={4}>
          {current === 2 && isTeamOneSelected ? (
            <>{isTeamOneSelected.teamName}</>
          ) : (
            <>{current === 2 ? 'Select' : 'Selecting'} Team 1</>
          )}
        </Group>
      </Button>
      <Button
        data-guided-tour="team-select-2"
        isFullWidth
        isXSmall
        onClick={() => onChange(2)}
        isSecondary={current === 1}>
        <Group isVerticalCentered spaceSize={4}>
          {current === 1 && isTeamTwoSelected ? (
            <>{isTeamTwoSelected.teamName}</>
          ) : (
            <>{current === 1 ? 'Select' : 'Selecting'} Team 2</>
          )}
        </Group>
      </Button>
    </Group>
  );
};

TeamsSelector.propTypes = {
  current: PropTypes.number,
  isTeamOneSelected: PropTypes.object,
  isTeamTwoSelected: PropTypes.object,
  onChange: PropTypes.func,
};

export default TeamsSelector;
