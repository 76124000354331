import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { SessionContext } from 'containers/SessionManager';

const useUserData = () => {
  const { session, reloadSession } = useContext(SessionContext);
  const { userData } = useSelector(state => state.main);
  return [{ ...userData, ...session }, reloadSession];
};

export default useUserData;
