import { camelCaseToSpace } from 'services/utilities';

export const defaultMetrics = {
  QB: ['passingYards', 'touchdowns', 'interceptions'],
  RB: [
    'rushingYardsPerAttempt',
    'rushingAttempts',
    'touchdowns',
    'rushingYards',
  ],
  WR: ['receivingYards', 'receptions', 'touchdowns'],
  TE: ['receivingYards', 'receptions', 'touchdowns'],
  K: ['extraPointsMade', 'fieldGoalsMade'],
  OLB: ['soloTackles', 'assistedTackles'],
  ILB: ['soloTackles', 'assistedTackles'],
  DE: ['soloTackles', 'assistedTackles', 'quarterbackHits'],
};

export default function formatMetrics(metrics, position) {
  return Object.keys(metrics)
    .map(metricKey => {
      if (defaultMetrics[position].indexOf(metricKey) !== -1) {
        return {
          name: metricKey,
          label: camelCaseToSpace(metricKey, true),
          value: metrics[metricKey],
        };
      }
    })
    .filter(i => i != undefined);
}
