import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Group } from 'atoms';

const TeamSlider = ({ teams, onSelect }) => {
  return (
    <Group
      spaceSize={10}
      style={{
        overflowX: 'auto',
        margin: '10px 15px',
      }}>
      {teams.map(
        team =>
          team && (
            <Avatar
              data-guided-tour="team-avatar"
              data-guided-tour-emulate-click="team-logo"
              key={team.source}
              source={team.source}
              size={80}
              {...{
                [team.isSelected ? 'isPrimary' : 'isTertiary']: true,
              }}
              style={{
                flexShrink: '0',
              }}
              onClick={() => onSelect(team)}
            />
          )
      )}
    </Group>
  );
};

TeamSlider.propTypes = {
  teams: PropTypes.arrayOf(
    PropTypes.exact({
      source: PropTypes.string.isRequired,
      isSelected: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  onSelect: PropTypes.func.isRequired,
};

export default TeamSlider;
