import React from 'react';
import PropTypes from 'prop-types';

import colors from 'assets/themes/colors';
import { Button, GeneralText, Group, Space, Title } from 'atoms';
import { ContainerWithBackground } from 'molecules';

const GameTypeSelection = ({
  title,
  description,
  backgroundSource,
  height,
  onSelect,
  ...rest
}) => (
  <ContainerWithBackground source={backgroundSource} height={height} {...rest}>
    <Group
      data-guided-tour="game-type"
      isVertical
      isCentered
      isVerticalCentered
      padding={20}
      style={{ height: '100%', maxWidth: 415, margin: 'auto' }}>
      <Title color={colors.white}>{title}</Title>
      <Space isSmall isVertical />

      <GeneralText color={colors.white}>{description}</GeneralText>

      <Space isLarge isVertical />

      <Group isCentered isFullWidth style={{ padding: '0 20px' }}>
        <Button
          isSmall
          isFullWidth
          data-guided-tour="select-free-contest"
          onClick={() => onSelect(true)}>
          Select free contests
        </Button>
      </Group>

      <Space isVertical />

      <Group isCentered isFullWidth style={{ padding: '0 20px' }}>
        <Button
          isXSmall
          isBare
          isFullWidth
          disabled
          onClick={() => onSelect(false)}>
          <GeneralText color={colors.white}>
            <u>Select contests (Coming soon!)</u>
          </GeneralText>
        </Button>
      </Group>
    </Group>
  </ContainerWithBackground>
);

GameTypeSelection.defaultProps = {
  height: '50%',
  onSelect: () => {},
};

GameTypeSelection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  backgroundSource: PropTypes.string.isRequired,
  height: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default GameTypeSelection;
