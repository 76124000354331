import { camelCaseToSpace } from 'services/utilities';
import { positions } from 'services/friendlyNames';
import parseDateTime from 'services/parseDateTime';

import formatMetrics from 'services/formatMetrics';

const extractCompetitors = data => {
  if (typeof data !== 'object') {
    console.warn('Cannot normalize teams: data its not an array.');
    return;
  }

  try {
    const teams = {
      [data[0].teamName]: data[0],
      [data[1].teamName]: data[1],
    };
    const competitors = [
      ...(data[0].competitors || []),
      ...(data[1].competitors || []),
    ].map(item => {
      const currentTeam = teams[item.competitor.teamName];
      const isHome = currentTeam.homeTeam;

      const competitionStart = parseDateTime(
        currentTeam.competitionStart,
        'hh:mm A'
      );
      const competitionStartDate = parseDateTime(
        currentTeam.competitionStart,
        'MMM DD, YYYY'
      );

      return {
        id: item.competitor.id,

        fullName: item.competitor.firstName + ' ' + item.competitor.lastName,

        firstName: item.competitor.firstName,
        lastName: item.competitor.lastName,

        homeTeam: isHome,

        competitionStart,

        competitionStartDate,

        teamName: currentTeam.teamName,
        teamNameLogo: isHome
          ? currentTeam.homeTeamLogoUrl
          : currentTeam.awayTeamLogoUrl,

        homeTeamName: currentTeam.homeTeamName,
        homeTeamLogoUrl: currentTeam.homeTeamLogoUrl,

        awayTeamName: currentTeam.awayTeamName,
        awayTeamLogoUrl: currentTeam.awayTeamLogoUrl,

        position: positions[item.competitor.position],
        status: item.isBackupCompetitor ? 'BACKUP' : 'LINEUP',

        seasonAverage: formatMetrics(
          item.competitor.metricsAvgs,
          item.competitor.position
        ),

        projections: {
          overunder: formatMetrics(
            item.competitor.projections.overUnder,
            item.competitor.position
          ),
          statpicks: formatMetrics(
            item.competitor.projections.statPicks,
            item.competitor.position
          ),
        },

        picture: item.competitor.headshotUrl,

        stats: {
          name: item.metric,
          label: camelCaseToSpace(item.metric, true),
          value: item.competitor.metricsAvgs[item.metric],
        },
      };
    });

    return competitors;
  } catch (error) {
    console.error('Cannot normalize teams: ' + error);
  }
};

export default extractCompetitors;
