export default {
  name: 'gamePlayTour',
  steps: [
    {
      target: 'body',
      title: 'Game Play',
      content:
        "Your teams have been selected. Now let's make some predictions. Let's show you the ropes.",
      placement: 'center',
    },
    {
      target: '[data-guided-tour="participants-vs-max-entries"]',
      content:
        'The number of current entries in the contest and the total maximum entries. Free contests will run even if not full.',
    },
    {
      target: '[data-guided-tour="entry-fee-free"]',
      content:
        'Right now, we are only launching with free contests, but when we have paid contests, you will see the entry types here: $5.00, $10.00, etc. ',
    },
    {
      target: '[data-guided-tour="payout-free"]',
      content:
        'For Free Contests, we have two types of payouts: Top 50 where 50% of participants win and Top 25 where the top 25% of participants win. Our free contests pay M$0.25, our very own Meteor Dollars! When we launch paid games, you can use your Meteor Cash to enter paid contests and win REAL dollars.',
    },
    {
      target: '[data-guided-tour="selected-team-lineup"]',
      content:
        "Your selected team will always be on the left. Your selected team's 'on field' competitor will always show on the right.",
    },
    //These steps look redundant but are required due to how we coded the app.
    //The guided tour will just skip any steps if it cannot match the selector.
    {
      target: '[data-guided-tour="card-swiper-statpicks"]',
      title: 'Statpicks',
      content:
        'For a provided metric, you will input your prediction by clicking on ---- at the bottom of the player card. You can use the + and - as well if you prefer!',
    },
    {
      target: '[data-guided-tour="card-swiper-over-under"]',
      title: 'Over and Under',
      content:
        'You will be shown a metric. Just click on the thumbs up (over) or thumbs down (under) if you predict that the player will go over or under the provided metric.',
    },
    {
      target: '[data-guided-tour="arrow-right-statpicks"]',
      title: 'Swiping',
      content:
        'After making your prediction for a player, simply swipe or click on the arrows to move to the next one.',
    },
    {
      target: '[data-guided-tour="arrow-right-over-under"]',
      title: 'Swiping',
      content:
        'After making your prediction for one card, just swipe or click the arrows to move to the next one.',
    },
  ],
};
