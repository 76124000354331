import React from 'react';
import PropTypes from 'prop-types';

import colors from 'assets/themes/colors';
import { GeneralText as Text, Title, Group } from 'atoms';

const PersonHeading = ({ children, subtitle, isSubtitleBlack, ...rest }) => {
  return (
    <Group isVertical isCentered {...rest}>
      <Title isMedium>{children}</Title>
      {subtitle && (
        <Text isMedium color={isSubtitleBlack ? colors.black : colors.primary}>
          {subtitle}
        </Text>
      )}
    </Group>
  );
};

PersonHeading.defaultProps = {
  subtitle: null,
};

PersonHeading.propTypes = {
  children: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  isSubtitleBlack: PropTypes.bool,
};

export default PersonHeading;
